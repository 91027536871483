export class Device {
	constructor() {
		if (this.isTouch()) {
			document.documentElement.classList.add('touch');
		} else {
			document.documentElement.classList.add('no-touch');
		}
	}

	public isTouch() {
		return 'ontouchstart' in document.documentElement;
	}
}
export const device = new Device();
