import { TweenMax } from 'gsap/TweenMax';
import { ProjectModule } from './ProjectModule';

export class VideoLoopModule extends ProjectModule {
	private video: HTMLVideoElement;
	private source: HTMLSourceElement;
	private videoLoaded = false;

	constructor(element: HTMLDivElement) {
		super(element);
		this.video = this.element.querySelector('video');
		this.source = this.element.querySelector('source');
	}

	protected onEnterScreen() {
		super.onEnterScreen();
		if (!this.videoLoaded) {
			this.loadVideo();
		} else {
			this.playVideo();
		}
	}

	protected onLeaveScreen() {
		super.onLeaveScreen();
		this.unload();
	}

	public load() {}

	public loadVideo() {
		this.videoLoaded = true;
		this.source.setAttribute('src', this.source.getAttribute('data-src'));
		this.video.onloadeddata = () => {
			TweenMax.to(this.element, 0.5, { opacity: 1 });
		};
		this.video.load();
		this.video.play();
	}

	private playVideo() {
		if (this.videoLoaded) {
			this.video.play();
		}
	}

	private pauseVideo() {
		if (this.videoLoaded) {
			this.video.pause();
		}
	}

	public unload() {
		this.pauseVideo();
	}
}
