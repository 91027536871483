import {About} from "./About";
import {Latest} from "./Latest";
import {Contact} from "./Contact";
import {Press} from "./Press";
import {sunDial} from "../modules/Sundial/Sundial";
import {CursorManager, cursorManager} from "../modules/CursorManager/CursorManager";
import {ProjectMenu} from "../modules/ProjectMenu/ProjectMenu";
import {Power1, Power2, TweenMax} from "gsap/TweenMax";
import {canvasView} from "../modules/CanvasView/CanvasView";
import {projects} from "../modules/Projects/Projects";
import {emitter, EVENTS} from "../utils/emitter";
import {menu} from "../modules/Menu/Menu";
import {landing} from "./Landing";

export class InfoSections {

	public element: HTMLElement;
	public showing: boolean;
	private sectionsEl: HTMLElement;
	private aboutSection: About;
	private contactSection: Contact;
	private pressSection: Press;
	private latestSection: Latest;
	private closeButton: HTMLElement;
	private scrollContent: HTMLElement;

	constructor() {
		this.element = document.querySelector('.InfoSections');
		this.sectionsEl = this.element.querySelector('.sections');
		this.closeButton = this.element.querySelector('.closeButton');
		this.scrollContent = this.element.querySelector('.scrollContent');
	}

	public init() {
		this.aboutSection = new About(this.element.querySelector('.about.section'));
		this.pressSection = new Press(this.element.querySelector('.press.section'));
		this.contactSection = new Contact(this.element.querySelector('.contact.section'));
		this.latestSection = new Latest(this.element.querySelector('.latest.section'));
		this.closeButton.addEventListener('click', this.closeHandler);

		emitter.on(EVENTS.colorChange, e => {
			const color = e.colors.foreground;
			const sectionsEls = [this.aboutSection.element, this.pressSection.element,
				this.contactSection.element, this.latestSection.element]
			if (color == '#000000') {
				TweenMax.set(sectionsEls, {borderColor: 'rgba(0, 0, 0, .1)'})
			} else {
				TweenMax.set(sectionsEls, {borderColor: 'rgba(255, 255, 255, .1)'})
			}

		});
	}

	resize() {
		this.latestSection.resize();
	}

	closeHandler = (e) => {
		e.preventDefault();
		this.hide();
	}

	public async show(sectionId) {
		if (!this.showing) {
			this.showing = true;
			landing.paused = true;
			canvasView.blur();
			projects.blur();
			menu.hideBackToStart();

			const speed = .6;
			const delay = .5;

			TweenMax.to(this.element, speed, {
				autoAlpha: 1,
				delay: delay,
				onStart: () => {
					this.element.style.display = 'block'
					this.latestSection.updateTotalPages();
					this.scrollTo(sectionId);
				},
				onComplete: () => {
					//resolve();
				}
			});

			cursorManager.setType(CursorManager.POINTER);
			sunDial.hide();
		} else {
			this.scrollTo(sectionId);
		}
	}

	hide() {
		return new Promise((resolve, reject) => {
			if (this.showing) {
				landing.paused = false;
				this.showing = false;
				this.aboutSection.pause();
				menu.showBackToStart();

				const speed = .5;
				TweenMax.to(this.element, speed, {
					autoAlpha: 0,

					onComplete: () => {
						this.element.style.display = 'none';
					}
				});

				TweenMax.delayedCall(.5, () => {
					sunDial.show();
					canvasView.unblur();
					projects.unblur();
				});
			} else {
				resolve();
			}
		});
	}

	scrollTo(sectionId) {
		let y = 0;
		if (sectionId != 'about') {
			y = (document.querySelector('.scrollContent .section.' + sectionId) as HTMLElement).offsetTop;
		}

		TweenMax.to(this.scrollContent, 1, { scrollTo: y - 40, ease: Power2.easeInOut });
	}
}

export const infoSections = new InfoSections();





