import { Project } from './Project';
import { Point } from '../../utils/Point';
import { Rect } from '../../utils/Rect';
import { rectangleIntersection } from '../../utils/MathHelpers';
import { Blob } from '../CanvasView/Blob';
import { cursorManager } from '../CursorManager/CursorManager';
import { ImageModule } from './ImageModule';
import { Linear, TweenMax } from 'gsap/TweenMax';
import { emitter, EVENTS } from '../../utils/emitter';
import { WindowManager } from '../../utils/WindowManager';
import { mobileProjectNav } from './MobileProjectNav';

export class Projects {
	public element: HTMLDivElement;
	public activeProject: Project;
	private slideshowContainer: HTMLDivElement;
	private slideshowBackground: HTMLDivElement;
	private slideshowForeground: HTMLDivElement;
	private closeButton: HTMLDivElement;
	private landingElement: HTMLDivElement;
	private projects: Array<Project>;
	private static ELEMENT_IDENTIFIER: string = '.Projects';

	constructor() {}

	public init() {
		this.element = document.querySelector(Projects.ELEMENT_IDENTIFIER);
		this.landingElement = document.querySelector('.Landing');
		this.slideshowContainer = this.element.querySelector('.slideshowBackground');
		this.slideshowForeground = this.element.querySelector('.slideshowForeground');
		this.slideshowBackground = this.element.querySelector('.slideshowBackground .background');
		this.projects = Array.from(this.element.querySelectorAll('.project')).map(item => {
			return new Project(item as HTMLDivElement);
		});
		// this.mobileCompass = document.querySelector('.mobile-compass');
		this.closeButton = this.element.querySelector('.close');
		this.closeButton.addEventListener('click', this.closeSlideshowHandler);
	}

	public load() {
		// this.projects.forEach(project => {
		// 	project.load();
		// });
	}

	public resize() {
		if (this.activeProject) {
			this.activeProject.resize();
		}
		this.slideshowForeground.style.bottom = this.element.clientHeight - WindowManager.height + 42 + 'px';
	}

	public render() {
		if (this.activeProject) {
			this.activeProject.render();
		}
	}

	public getProject(slug: String): Project {
		let foundProject = null;
		this.projects.forEach(project => {
			if (project.slug == slug) {
				foundProject = project;
			}
		});
		return foundProject;
	}

	public setPanPosition(position: Point) {
		if (this.activeProject) {
			this.activeProject.setPanPosition(position);
		}
	}

	public panBy(delta: Point) {
		if (this.activeProject) {
			this.activeProject.panBy(delta);
		}
	}

	public showProject(slug: String) {
		if (!this.activeProject || slug != this.activeProject.slug) {
			this.activeProject = this.getProject(slug);
			this.activeProject.transitionIn(2.3);
			this.updateCompass(cursorManager.lastTargetMousePoint);
			//this.showMobileCompass();
		}
	}

	public hideProject() {
		if (this.activeProject) {
			this.activeProject.transitionOut();
			this.activeProject = null;
			//this.hideMobileCompass();
		}
	}

	public showSlideshow(imageModule: ImageModule) {
		mobileProjectNav.hide();
		this.element.style.zIndex = '2';
		TweenMax.to([this.slideshowContainer, this.slideshowForeground], 0.7, { autoAlpha: 1 });
		//this.hideMobileCompass();
		this.element.classList.add('slideshow');
		this.activeProject.hideOtherProjectModuleElements(imageModule.element);
	}

	public hideSlideshow() {
		mobileProjectNav.show();
		this.element.style.zIndex = '1';
		TweenMax.to([this.slideshowContainer, this.slideshowForeground], 1, { autoAlpha: 0 });
		//this.showMobileCompass();
		this.element.classList.remove('slideshow');
		this.activeProject.showProjectModuleElements();
	}

	public blur() {
		TweenMax.to(this.element, 0.5, {
			opacity: 0
		});
	}

	public unblur() {
		TweenMax.to(this.element, 0.5, {
			opacity: 1
		});
	}

	private closeSlideshowHandler(e) {
		emitter.emit(EVENTS.hideSlideshow, { instance: this });
	}

	public offsetIntersectingBlobs(blobs: Array<Blob>, animateBlobs: boolean = false, checkHostBounds: boolean = false) {
		blobs.forEach(blob => {
			this.offsetIntersectingBlob(blob, animateBlobs, checkHostBounds);
		});
	}

	public offsetIntersectingBlob(blob: Blob, animateHidingBlobs: boolean = false, checkHostBounds: boolean = false) {
		if (!this.activeProject) {
			return;
		}

		blob.show();

		const threshold = WindowManager.height * 2;
		const blobPosition = blob.getLoopPanPosition();
		const blobRect: Rect = {
			x: blobPosition.x - threshold / 2,
			y: blobPosition.y - threshold / 2,
			width: threshold,
			height: threshold
		};

		for (let i = 0; i < this.activeProject.projectModuleInnerWrappers.length; i++) {
			if (i == 0 && blob.isHost(this.activeProject, checkHostBounds)) {
				break;
			}
			const isIntersection = rectangleIntersection(blobRect, this.activeProject.getModuleRect(this.activeProject.projectModuleInnerWrappers[i]));
			if (isIntersection || this.activeProject.slug == blob.slug) {
				blob.hide(animateHidingBlobs);
				break;
			}
		}
	}

	public updateCompass(point: Point) {
		if (this.activeProject) {
			const angle = this.activeProject.getAngleToNextModule({ x: point.x, y: point.y });
			//TweenMax.set(this.mobileCompass, { rotation: angle });
			cursorManager.setCompassAngle(angle);
		}
	}
}

export const projects = new Projects();
