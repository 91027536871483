import { Emitter, EVENTS } from '../../utils/emitter';
import { Sundial } from '../Sundial/Sundial';
import { TweenMax } from 'gsap/TweenMax';
import { device } from '../../utils/device';

export class MobileProjectNav extends Emitter {
	public element: HTMLElement;
	private leftArrow: HTMLElement;
	private rightArrow: HTMLElement;

	constructor() {
		super();

		this.element = document.querySelector('.mobileProjectNav');

		this.leftArrow = this.element.querySelector('.leftArrow');
		this.rightArrow = this.element.querySelector('.rightArrow');

		this.leftArrow.addEventListener('click', this.previousHandler.bind(this));
		this.rightArrow.addEventListener('click', this.nextHandler.bind(this));
	}

	public show() {
		if (!device.isTouch()) {
			return;
		}
		TweenMax.to(this.element, 1, { y: -this.element.clientHeight });
	}

	public hide() {
		if (!device.isTouch()) {
			return;
		}
		TweenMax.to(this.element, 1, { y: 0 });
	}

	private previousHandler(e) {
		e.preventDefault();
		this.emit(EVENTS.prevModule, {});
	}

	private nextHandler(e) {
		e.preventDefault();
		this.emit(EVENTS.nextModule, {});
	}

	public disableNext() {
		this.rightArrow.classList.add('disabled');
	}

	public disablePrev() {
		this.leftArrow.classList.add('disabled');
	}

	public enableNext() {
		this.rightArrow.classList.remove('disabled');
	}

	public enablePrev() {
		this.leftArrow.classList.remove('disabled');
	}
}

export const mobileProjectNav = new MobileProjectNav();
