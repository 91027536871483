import { Back, TweenMax } from 'gsap/TweenMax';
import { BlobRing } from './BlobRing';
import { clamp, distance, mod } from '../../utils/MathHelpers';
import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { Emitter, EVENTS } from '../../utils/emitter';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { State } from '../../utils/State';
import { router } from '../../utils/router';
import { canvasView, CanvasView } from './CanvasView';
import { Project } from '../Projects/Project';
import { projects } from '../Projects/Projects';
import { device } from '../../utils/device';

export class Blob extends Emitter {
	public element: HTMLDivElement;
	public parent: CanvasView;
	public radius: number;
	public baseRadius: number;
	public hoverRadiusMultiplier = 1;
	public slug: string;
	public isRendered: boolean = true;
	public isOnScreen: boolean = false;
	public context: CanvasRenderingContext2D;
	public centerPoint: Point = { x: 0, y: 0 };
	public infoEl: HTMLDivElement;
	public originPoint: Point = { x: 0, y: 0 };
	public ringScaleX = 1.2;
	public showing: boolean = true;

	private panPosition: Point = { x: 0, y: 0 };
	private easedScrollPoint: Point = { x: 0, y: 0 };
	private easedInfoScrollPoint: Point = { x: 0, y: 0 };
	private focusBounds = { min: 0.6, max: 1 };
	private hitAreaEl: HTMLDivElement;
	private textHitAreaEl: HTMLDivElement;
	private bulletElement: HTMLDivElement;
	private rings: Array<BlobRing> = [];
	private active: boolean;
	private numRings = 0;
	private externalUrl;
	private activeFocusAmount = 0;
	private floatOffsetY = 0;

	readonly easeAmount: number;
	readonly inactiveEaseAmount: number;
	readonly activeEaseAmount: number;
	readonly numPoints = 5;
	readonly irregularness = 0.3;
	readonly ringSpace = 15;
	readonly random: number = Math.random();

	constructor(context: CanvasRenderingContext2D, element: HTMLDivElement, parent: CanvasView) {
		super();
		this.element = element;
		this.parent = parent;
		this.bulletElement = this.element.querySelector('.bullet');
		this.context = context;
		this.baseRadius = this.radius = parseInt(this.element.getAttribute('data-radius'));
		this.numRings = clamp(Math.floor(this.baseRadius / 25), 3, 4);
		this.slug = this.element.getAttribute('data-slug');
		this.externalUrl = this.element.getAttribute('data-external-url');
		this.hitAreaEl = this.element.querySelector('.hit-area');
		this.textHitAreaEl = this.element.querySelector('.text-hit-area');

		if (this.slug.length || this.externalUrl.length) {
			this.hitAreaEl.addEventListener('mouseover', this.onBlobOverHandler);
			this.hitAreaEl.addEventListener('mouseout', this.onBlobOutHandler);
			this.hitAreaEl.addEventListener('click', this.onBlobClickHandler);
			this.textHitAreaEl.addEventListener('mouseover', this.onBlobOverHandler);
			this.textHitAreaEl.addEventListener('mouseout', this.onBlobOutHandler);
			this.textHitAreaEl.addEventListener('click', this.onBlobClickHandler);
		}
		this.infoEl = this.element.querySelector('.info');

		this.originPoint = {
			x: parseFloat(this.element.getAttribute('data-position-x')),
			y: parseFloat(this.element.getAttribute('data-position-y'))
		};
		const ease = this.radius / 10 + (2.5 * Math.random() + 2.5);
		this.inactiveEaseAmount = this.easeAmount = ease;
		this.activeEaseAmount = 10;
		if (device.isTouch()) {
			this.inactiveEaseAmount = this.easeAmount = ease / 2;
			this.activeEaseAmount = 5;
		}
		this.random = Math.random();

		// define a shape for the rings to share
		const basePoints = [];
		for (let i = 0; i < this.numPoints; i++) {
			const azimuth = Math.PI - ((Math.PI * 2) / this.numPoints) * (i + 1);
			const randomX = (Math.random() - 0.5) * this.irregularness;
			const randomY = (Math.random() - 0.5) * this.irregularness;
			const point = {
				x: Math.cos(azimuth) - randomX,
				y: Math.sin(azimuth) - randomY
			};
			basePoints.push(point);
		}

		for (let i = this.numRings; i--; ) {
			this.rings.push(new BlobRing(basePoints, this));
		}
	}

	public setPanPosition(position: Point) {
		this.panPosition = position;
	}

	public getPanPosition() {
		return this.panPosition;
	}

	public getLoopPanPosition() {
		return this.getLoopPosition(this.panPosition);
	}

	public getDistanceToAnchor() {
		const radius = this.getActiveRadius();
		return {
			x: -this.centerPoint.x + canvasView.blobAnchorMargin.x + radius.x,
			y: -this.centerPoint.y + canvasView.blobAnchorMargin.y + radius.y
		};
	}

	public getOrigin() {
		return {
			x: this.originPoint.x * canvasView.entireCanvasSize.width,
			y: this.originPoint.y * canvasView.entireCanvasSize.height
		};
	}

	public getLoopPosition(point: Point) {
		const origin = this.getOrigin();
		return {
			x: mod(origin.x + point.x, canvasView.entireCanvasSize.width) - (canvasView.entireCanvasSize.width - WindowManager.landingWidth) / 2,
			y: mod(origin.y + point.y, canvasView.entireCanvasSize.height) - (canvasView.entireCanvasSize.height - WindowManager.landingHeight) / 2
		};
	}

	public render(time: number) {
		//Todo clean this up? Blob into have its own render? optimze?
		this.easedScrollPoint.x += (this.panPosition.x - this.easedScrollPoint.x) / (this.easeAmount * this.parent.easeModifier);
		this.easedScrollPoint.y += (this.panPosition.y - this.easedScrollPoint.y) / (this.easeAmount * this.parent.easeModifier);

		const loopPosition = this.getLoopPosition(this.easedScrollPoint);
		this.centerPoint.x = loopPosition.x;
		this.floatOffsetY = Math.sin(time / 7 + this.random * 100) * 20;
		this.centerPoint.y = loopPosition.y + this.floatOffsetY;

		this.easedInfoScrollPoint.x += (this.panPosition.x - this.easedInfoScrollPoint.x) / ((this.easeAmount / 1.2) * this.parent.easeModifier);
		this.easedInfoScrollPoint.y += (this.panPosition.y - this.easedInfoScrollPoint.y) / ((this.easeAmount / 1.2) * this.parent.easeModifier);

		if (this.isRendered) {
			const loopPositionInfo = this.getLoopPosition(this.easedInfoScrollPoint);
			const infoX = loopPositionInfo.x;
			const infoY = loopPositionInfo.y + Math.sin(time / 6 + this.random * 50) * 20;
			TweenMax.set(this.element, { x: infoX, y: infoY });
			this.radius = this.getRadiusAtPoint(this.centerPoint, this.parent.introBlobRadius, this.hoverRadiusMultiplier);
			// const scale =  this.radius * 2 + 25;
			// TweenMax.set(this.hitAreaEl, {scaleX: scale, scaleY, scale})
			this.hitAreaEl.style.width = this.hitAreaEl.style.height = this.radius * 2 + 25 + 'px';
			TweenMax.set(this.hitAreaEl, { x: -this.radius, y: -this.radius });
			TweenMax.set(this.infoEl, { x: this.radius + 20 });
		}

		const activeRadius = this.getActiveRadius();
		const blobPoint = { x: this.centerPoint.x, y: this.centerPoint.y };
		if (
			blobPoint.x > -(activeRadius.x + this.infoEl.clientWidth) &&
			blobPoint.x < WindowManager.landingWidth + activeRadius.x &&
			blobPoint.y > -(activeRadius.y + this.infoEl.clientHeight) &&
			blobPoint.y < WindowManager.landingHeight + activeRadius.y
		) {
			this.enterScreen();

			if (this.isRendered) {
				for (let i = 0; i < this.rings.length; i++) {
					this.rings[i].render(time, this.radius - i * (this.ringSpace * this.parent.introBlobRadius * this.hoverRadiusMultiplier * this.parent.viewportRadiusMultiplier));
				}
			}
		} else {
			this.leaveScreen();
		}
	}

	public isHost(project: Project, checkHostBounds: boolean = false) {
		if (project.slug == this.slug) {
			if (checkHostBounds && !project.transitioning) {
				let entryPos = { x: 0, y: 0 };
				const activeRad = this.getActiveRadius();
				const panDis = project.getPannedDistance();
				entryPos.x = Math.abs(Math.round(panDis.x - this.centerPoint.x + activeRad.x + canvasView.blobAnchorMargin.x));
				entryPos.y = Math.abs(Math.round(panDis.y - this.centerPoint.y + activeRad.y + canvasView.blobAnchorMargin.y));
				if (entryPos.x <= 100 && entryPos.y <= 100) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		}
		return false;
	}

	// todo: only animate blobs that are already on screen?
	public show(animate = false) {
		if (!this.showing) {
			const speed = animate && this.isOnScreen ? 0.5 : 0;
			this.showText(speed);
			this.showing = true;
			this.isRendered = true;
			TweenMax.to(this, speed, { hoverRadiusMultiplier: 1 });
		}
	}

	// todo: only animate blobs that are already on screen?
	public hide(animate = false) {
		if (this.showing) {
			const speed = animate && this.isOnScreen ? 0.5 : 0;
			this.hideText(speed);
			this.showing = false;
			TweenMax.to(this, speed, {
				hoverRadiusMultiplier: 0,
				onComplete: () => {
					this.isRendered = false;
				}
			});
		}
	}

	public showText(speed = 0.5) {
		TweenMax.to(this.element, speed, { autoAlpha: 1 });
	}

	public hideText(speed = 0.5) {
		TweenMax.to(this.element, speed, { autoAlpha: 0 });
	}

	public transitionIn(delay) {
		TweenMax.to(this.element, 1, { opacity: 1, delay: delay });
	}

	public setColor(color: string, speed: number) {
		for (let i = this.rings.length; i--; ) {
			this.rings[this.rings.length - i - 1].setColor(color, speed);
		}
	}

	public activate() {
		TweenMax.to(this, 2, { easeAmount: this.activeEaseAmount });
		TweenMax.to(this, 2, { hoverRadiusMultiplier: 1, activeFocusAmount: 1, ease: Back.easeOut });
		this.active = true;
	}

	public deactivate() {
		TweenMax.to(this, 2, { easeAmount: this.inactiveEaseAmount });
		TweenMax.to(this, 2, { activeFocusAmount: 0, ease: Back.easeOut });
		this.active = false;
	}

	public getRadiusAtPoint(point, introBlobRadius = 1, hoverRadius = 1) {
		const distanceFromWindowCenter = distance(point.x, point.y, WindowManager.landingHalfWidth, WindowManager.landingHalfHeight);
		let focusAmount = this.focusBounds.max - (this.focusBounds.max - this.focusBounds.min) * clamp(distanceFromWindowCenter / WindowManager.distanceFromCenterToCorner, this.activeFocusAmount, 1);
		return this.baseRadius * focusAmount * introBlobRadius * hoverRadius * this.parent.viewportRadiusMultiplier;
	}

	// todo: combine this with getRadiusAtPOint to reduce code redundency
	public getActiveRadius() {
		const radiusX = this.baseRadius * this.ringScaleX * canvasView.viewportRadiusMultiplier * this.focusBounds.min;
		const radiusY = this.baseRadius * canvasView.viewportRadiusMultiplier * this.focusBounds.min;
		return { x: radiusX, y: radiusY };
	}

	private leaveScreen() {
		if (this.isOnScreen) {
			this.isOnScreen = false;
		}
	}

	private enterScreen() {
		if (!this.isOnScreen) {
			this.isOnScreen = true;
			this.emit(EVENTS.enterScreen, { blob: this });
		}
	}

	private onBlobOverHandler = e => {
		canvasView.idleSpeed = { x: 0, y: 0 };
		cursorManager.expandRings();
		TweenMax.to(this, 0.8, { hoverRadiusMultiplier: 1.15, ease: Back.easeOut });
		if (this.externalUrl) {
			cursorManager.setType(CursorManager.OPEN_OUTSIDE);
		} else if (this.active) {
			cursorManager.setType(CursorManager.CLOSE);
		} else {
			cursorManager.setType(CursorManager.OPEN);
		}
	};

	private onBlobOutHandler = e => {
		if (!projects.activeProject) {
			canvasView.idleSpeed = { x: CanvasView.MAX_IDLE_SPEED_X, y: CanvasView.MAX_IDLE_SPEED_Y };
		}
		cursorManager.contractRings();
		TweenMax.to(this, 0.8, { hoverRadiusMultiplier: 1, ease: Back.easeOut });
		if (State.currentView.name == 'project') {
			cursorManager.setType(CursorManager.COMPASS);
		} else {
			cursorManager.setType(CursorManager.DEFAULT);
		}
	};

	private onBlobClickHandler = e => {
		if (projects.activeProject && projects.activeProject.transitioning) {
			return;
		}

		if (cursorManager.isDragClick) {
			cursorManager.contractRings();
			if (this.slug) {
				if (this.active) {
					cursorManager.setType(CursorManager.DEFAULT);
					router.route('/');
				} else {
					router.route('/project/' + this.slug);
				}
			} else if (this.externalUrl) {
				window.open(this.externalUrl);
			}
		}
	};
}
