import { TweenMax } from 'gsap/TweenMax';
import Player from '@vimeo/player';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { ProjectModule } from './ProjectModule';
import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { emitter, EVENTS } from '../../utils/emitter';

export class IntroductionModule extends ProjectModule {
	private vimeoPlayer: Player;
	private vimeoVideoPlaying: Boolean;
	private vimeoVideoWrapper: HTMLDivElement;
	private videoLoop: HTMLVideoElement;
	private videoLoopLoaded: boolean;
	private videoLoopSource: HTMLSourceElement;
	private introImage: HTMLImageElement;
	private introText: HTMLElement;

	constructor(element: HTMLDivElement) {
		super(element);
		this.vimeoVideoWrapper = element.querySelector('.vimeoVideoWrapper');
		if (this.vimeoVideoWrapper) {
			this.vimeoVideoWrapper.addEventListener('mouseenter', this.vimeoVideoOverHandler);
			this.vimeoVideoWrapper.addEventListener('mouseleave', this.vimeoVideoOutHandler);
			this.vimeoVideoWrapper.addEventListener('click', this.videoClickHandler);
		}
		this.videoLoop = this.element.querySelector('video');
		this.videoLoopSource = this.element.querySelector('source');
		this.introImage = this.element.querySelector('.introImage');
		this.introText = this.element.querySelector('.introText p');
	}

	public load() {
		if (this.introImage) {
			emitter.emit(EVENTS.assetLoadStart, { asset: this.introImage });
			this.introImage.onload = () => {
				emitter.emit(EVENTS.assetLoadComplete, { asset: this.introImage });
			};
			this.introImage.src = this.introImage.getAttribute('data-src');
		}
	}

	protected onEnterScreen() {
		super.onEnterScreen();
		if (this.videoLoop) {
			if (!this.videoLoopLoaded) {
				this.loadVideoLoop();
			} else {
				this.playVideoLoop();
			}
		}
	}

	protected onLeaveScreen() {
		super.onLeaveScreen();
		this.unload();
	}

	private loadVideoLoop() {
		if (this.videoLoop) {
			this.videoLoopLoaded = true;
			this.videoLoopSource.setAttribute('src', this.videoLoopSource.getAttribute('data-src'));
			this.videoLoop.load();
			this.playVideoLoop();
		}
	}

	private loadVimeoVideo() {
		if (!this.vimeoPlayer && this.vimeoVideoWrapper) {
			const iframe = this.element.querySelector('iframe');
			iframe.setAttribute('src', iframe.getAttribute('data-src'));
			this.vimeoPlayer = new Player(iframe);
			this.vimeoPlayer.on('play', this.vimeoVideoPlayHandler);
			this.vimeoPlayer.on('pause', this.vimeoVideoPauseHandler);
		}
	}

	private playVideoLoop() {
		if (this.videoLoopLoaded && this.videoLoop) {
			this.videoLoop.play();
		}
	}

	private pauseVideoLoop() {
		if (this.videoLoopLoaded && this.videoLoop) {
			this.videoLoop.pause();
		}
	}

	private playVimeoVideo() {
		if (!this.vimeoPlayer) {
			this.loadVimeoVideo();
		}
		cursorManager.setType(CursorManager.PAUSE);
		this.vimeoPlayer.play();
	}

	private pauseVimeoVideo() {
		if (!this.vimeoPlayer) {
			return;
		}
		this.vimeoPlayer.pause();
	}

	private vimeoVideoOverHandler = e => {
		if (this.vimeoVideoPlaying) {
			cursorManager.setType(CursorManager.PAUSE);
		} else {
			cursorManager.setType(CursorManager.PLAY);
		}
		cursorManager.expandRings();
	};

	private vimeoVideoOutHandler = e => {
		cursorManager.setType(CursorManager.COMPASS);
		cursorManager.contractRings();
	};

	private videoClickHandler = e => {
		if (cursorManager.isDragClick) {
			if (this.vimeoVideoPlaying) {
				this.pauseVimeoVideo();
			} else {
				this.playVimeoVideo();
			}
		}
	};

	private vimeoVideoPlayHandler = () => {
		this.vimeoVideoPlaying = true;
	};

	private vimeoVideoPauseHandler = () => {
		this.vimeoVideoPlaying = false;
		if (this.isOnScreen) {
			cursorManager.setType(CursorManager.PLAY);
		} else {
			cursorManager.setType(CursorManager.COMPASS);
		}
	};

	public getCenter() {
		return {
			x: this.element.offsetLeft - WindowManager.landingHalfWidth + this.introText.clientWidth / 2,
			y: this.element.offsetTop - WindowManager.landingHalfHeight + this.introText.clientHeight / 2
		};
	}

	public unload() {
		this.pauseVideoLoop();
		this.pauseVimeoVideo();
	}
}
