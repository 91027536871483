import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { rectangleIntersection } from '../../utils/MathHelpers';

export class ProjectModule {
	public element: HTMLDivElement;
	public innerWrapper: HTMLDivElement;
	public isOnScreen: boolean;

	constructor(element) {
		this.element = element;
		this.innerWrapper = element.querySelector('.innerWrapper');
	}

	protected onEnterScreen() {
		this.isOnScreen = true;
	}

	protected onLeaveScreen() {
		this.isOnScreen = false;
	}

	public getPosition() {
		return this.innerWrapper.getBoundingClientRect();
	}

	public onPositionUpdate(position: Point) {
		const isOnScreen = rectangleIntersection(
			{
				x: this.element.offsetLeft + position.x,
				y: this.element.offsetTop + position.y,
				width: this.innerWrapper.clientWidth,
				height: this.innerWrapper.clientHeight
			},
			{
				x: 0,
				y: 0,
				width: WindowManager.landingWidth,
				height: WindowManager.landingHeight
			}
		);

		if (!this.isOnScreen && isOnScreen) {
			this.onEnterScreen();
		} else if (this.isOnScreen && !isOnScreen) {
			this.onLeaveScreen();
		}
	}

	public getCenter() {
		return {
			x: this.element.offsetLeft - WindowManager.landingHalfWidth + this.innerWrapper.clientWidth / 2,
			y: this.element.offsetTop - WindowManager.landingHalfHeight + this.innerWrapper.clientHeight / 2
		};
	}

	public load() {}

	public unload() {}
}
