import { Blob } from './Blob';
import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { Line } from './Line';
import { clamp, hexToRgb, lerpRGB, pointCircleIntersection, scale } from '../../utils/MathHelpers';
import { emitter, EVENTS } from '../../utils/emitter';
import { colorThemeManager } from '../ColorThemeManager/ColorThemeManager';
import { projects } from '../Projects/Projects';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { Power1, TweenMax } from 'gsap/TweenMax';
import { BackgroundGradient } from './BackgroundGradient';

export class CanvasView {
	private static ELEMENT_IDENTIFIER: string = '.CanvasView';
	public static MAX_IDLE_SPEED_X: number = -0.3;
	public static MAX_IDLE_SPEED_Y: number = -0.15;
	public element: HTMLDivElement;
	public easeModifier: number = 1;
	public blobs: Array<Blob>;
	public introBlobRadius: number = 0;
	public viewportRadiusMultiplier: number = 1;
	public entireCanvasSize: any = { width: 0, height: 0 };
	public blobAnchorMargin = { x: 0, y: 50 };
	public idleSpeed = { x: 0, y: 0 };

	private entireCanvasSizeBounds: any = { minWidth: 2000, minHeight: 2000 };
	private panPosition: Point = { x: 0, y: 0 };
	private blobNodeList: NodeList;
	private lines: Array<Line> = [];
	private gradients: Array<BackgroundGradient> = [];
	private dpr: Array<number> = [1, 1.5, 2];
	private time: number = 0;
	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D;
	private backgroundColorTime = 0;
	private currentMousePoint: Point = { x: 0, y: 0 };
	private targetMousePoint: Point = { x: 0, y: 0 };
	private cursorEffectEasing: number = 5;
	private cursorSpeedModifer: number = 1;
	private linesEnabled: boolean = true;

	constructor() {}

	public init() {
		this.element = document.querySelector(CanvasView.ELEMENT_IDENTIFIER);
		this.blobNodeList = this.element.querySelectorAll('.blob');
		this.canvas = this.element.querySelector('canvas');
		this.context = this.canvas.getContext('2d');

		this.blobs = Array.from(this.blobNodeList).map(item => {
			const blob = new Blob(this.context, item as HTMLDivElement, this);
			blob.on(EVENTS.enterScreen, this.onBlobEnterScreenHandler);
			return blob;
		});

		const backgroundGradient1 = new BackgroundGradient(this.context, this, 600, { x: 0.25, y: 0.25 });
		const backgroundGradient2 = new BackgroundGradient(this.context, this, 800, { x: 0.75, y: 0.75 });
		this.gradients.push(backgroundGradient1);
		this.gradients.push(backgroundGradient2);

		cursorManager.on(EVENTS.mouseDown, e => {
			this.targetMousePoint.x = this.currentMousePoint.x = e.x;
			this.targetMousePoint.y = this.currentMousePoint.y = e.y;
			TweenMax.to(this, 1, { cursorSpeedModifer: 0.3 });
		});

		cursorManager.on(EVENTS.mouseUp, e => {
			TweenMax.to(this, 1, { cursorSpeedModifer: 1 });
		});

		cursorManager.on(EVENTS.mouseMove, e => {
			const offsetY = this.element.getBoundingClientRect().top;
			this.targetMousePoint = { x: e.x, y: e.y - offsetY };
		});

		emitter.on(EVENTS.colorChange, this.onColorThemeChange);
	}

	public resize = () => {
		const totalLines = Math.min(80, WindowManager.landingHeight / 12);
		this.lines = [];

		this.entireCanvasSize.width = Math.max(this.entireCanvasSizeBounds.minWidth, WindowManager.screenWidth * 2.2);
		this.entireCanvasSize.height = Math.max(this.entireCanvasSizeBounds.minHeight, WindowManager.screenHeight * 2.2);

		this.blobAnchorMargin = { x: WindowManager.width < 600 ? 50 : 150, y: 50 };

		this.viewportRadiusMultiplier = scale(WindowManager.landingWidth, 320, 1920, 1, 1.4);

		let dpr: number = this.dpr[0];
		if (WindowManager.landingWidth < 600) {
			dpr = this.dpr[2];
		}

		this.canvas.width = WindowManager.landingWidth * dpr;
		this.canvas.height = WindowManager.landingHeight * dpr;
		this.canvas.style.width = WindowManager.landingWidth + 'px';
		this.canvas.style.height = WindowManager.landingHeight + 'px';
		this.canvas.getContext('2d').scale(dpr, dpr);

		for (let i = 0; i < totalLines; i++) {
			const totalPoints = Math.min(30, Math.round(WindowManager.landingWidth / 40));
			const y = i / totalLines;
			const line = new Line(this.context, y + 0.001, totalPoints, this.blobs, colorThemeManager.currentColorSettings.lines);
			this.lines.push(line);
		}

		for (let i = this.lines.length; i--; ) {
			this.lines[i].resize();
		}

		for (let i = this.gradients.length; i--; ) {
			this.gradients[i].resize();
		}
	};

	public transitionIn() {
		cursorManager.setType(CursorManager.DEFAULT);
		this.idleSpeed = { x: CanvasView.MAX_IDLE_SPEED_X, y: CanvasView.MAX_IDLE_SPEED_Y };

		TweenMax.to(this, 1, { introBlobRadius: 1 });
		this.blobs.forEach(blob => {
			blob.transitionIn(1);
		});
	}

	public show = () => {
		this.element.style.display = 'block';
	};

	public hide = () => {
		this.element.style.display = 'none';
	};

	public render = () => {
		this.time += 0.1;
		this.context.clearRect(0, 0, WindowManager.landingWidth, WindowManager.landingHeight);

		const dx = this.targetMousePoint.x - this.currentMousePoint.x;
		const dy = this.targetMousePoint.y - this.currentMousePoint.y;
		this.currentMousePoint.x += dx / this.cursorEffectEasing;
		this.currentMousePoint.y += dy / this.cursorEffectEasing;

		this.panBy(this.idleSpeed);

		const cursorSpeed = clamp(Math.sqrt(dx * dx + dy * dy), 0, 30) * this.cursorSpeedModifer;

		for (let i = this.gradients.length; i--; ) {
			this.gradients[i].setPanPosition({ x: this.panPosition.x / 2, y: this.panPosition.y / 2 });
			this.gradients[i].render();
		}

		if (this.linesEnabled) {
			for (let i = this.lines.length; i--; ) {
				this.lines[i].move(this.time, this.currentMousePoint, cursorSpeed);
			}

			for (let i = this.lines.length; i--; ) {
				this.lines[i].render();
			}
		}

		for (let i = this.blobs.length; i--; ) {
			this.blobs[i].setPanPosition(this.panPosition);
			this.blobs[i].render(this.time);
		}
	};

	public panBy(delta: Point) {
		this.panPosition.x += delta.x;
		this.panPosition.y += delta.y;
	}

	public getPanPosition(): Point {
		return this.panPosition;
	}

	public getBlobByPoint(point: Point) {
		for (let i = 0; i < this.blobs.length; i++) {
			let blob = this.blobs[i];
			const isIntersect = pointCircleIntersection(point, { x: blob.centerPoint.x, y: blob.centerPoint.y, radius: blob.radius });
			if (isIntersect) {
				return blob;
			}
		}
	}

	public getBlobBySlug(slug: string) {
		for (let i = 0; i < this.blobs.length; i++) {
			let blob = this.blobs[i];
			if (blob.slug == slug) {
				return blob;
			}
		}
	}

	public showAllBlobs() {
		this.blobs.forEach(blob => {
			blob.show(true);
		});
	}

	public blur() {
		this.canvas.classList.add('blur');
		this.linesEnabled = false;
		this.blobs.forEach(blob => {
			if (blob.showing) {
				blob.hideText(0.25);
			}
		});
	}

	public unblur() {
		this.canvas.classList.remove('blur');
		this.linesEnabled = true;
		this.blobs.forEach(blob => {
			if (blob.showing) {
				blob.showText(0.25);
			}
		});
	}

	private onColorThemeChange = event => {
		this.setBlobColor(event.colors.blob, event.colors.blob_no_project, event.speed);
		this.setLineColor(event.colors.lines, 2.5);
		this.setBackgroundColor(event.colors.background, 2.5);
		this.setGradientColor(event.colors.blob, event.colors.background, 2.5);
	};

	private setLineColor(color, speed) {
		for (let i = this.lines.length; i--; ) {
			this.lines[i].setColor(color, speed);
		}
	}

	private setBlobColor(color, colorNoProject, speed) {
		for (let i = this.blobs.length; i--; ) {
			if (this.blobs[i].slug) {
				this.blobs[i].setColor(color, speed);
			} else {
				this.blobs[i].setColor(colorNoProject, speed);
			}
		}
	}

	private setBackgroundColor(newColor, speed) {
		const newColorRGB = hexToRgb(newColor);
		const lastColorRGB = colorThemeManager.lastColorSettings ? hexToRgb(colorThemeManager.lastColorSettings.background) : newColorRGB;
		const activeBlob = projects.activeProject ? this.getBlobBySlug(projects.activeProject.slug) : null;
		this.backgroundColorTime = 0;
		TweenMax.to(this, speed, {
			backgroundColorTime: 1,
			ease: Power1.easeOut,
			onUpdate: () => {
				let position = activeBlob
					? {
							x: activeBlob.centerPoint.x,
							y: activeBlob.centerPoint.y + this.element.parentElement.offsetTop
					  }
					: { x: 0.5, y: 0.5 };
				let backgroundColor = lerpRGB(lastColorRGB, newColorRGB, Math.max(0, (this.backgroundColorTime - 0.5) * 2));
				let startPct = 0;
				let endPct = 100 * this.backgroundColorTime;
				let gradient = 'radial-gradient(circle at ' + position.x + 'px ' + position.y + 'px, ' + newColor + ' ' + startPct + '%, ' + backgroundColor + ' ' + endPct + '%)';
				document.body.style.background = gradient;
			}
		});
	}

	private setGradientColor(innerColor, outerColor, speed) {
		for (let i = this.gradients.length; i--; ) {
			this.gradients[i].setColor(innerColor, outerColor, 2.5);
		}
	}

	private onBlobEnterScreenHandler = e => {
		projects.offsetIntersectingBlob(e.blob, false, true);
	};
}

export const canvasView = new CanvasView();
