import { TweenMax } from 'gsap/TweenMax';
import { Emitter, EVENTS } from '../../utils/emitter';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import Player from '@vimeo/player';

export class VideoModule extends Emitter {
	public element: HTMLDivElement;
	private player: Player;
	private posterImage: HTMLImageElement;
	private videoWrapper: HTMLDivElement;
	private playing: boolean;

	constructor(element: HTMLDivElement) {
		super();
		this.element = element;
		this.posterImage = element.querySelector('.poster');
		this.videoWrapper = element.querySelector('.videoWrapper');

		this.videoWrapper.addEventListener('mouseenter', this.videoOverHandler);
		this.videoWrapper.addEventListener('mouseleave', this.videoOutHandler);
		this.videoWrapper.addEventListener('click', this.videoClickHandler);
	}

	private videoOverHandler = e => {
		if (this.playing) {
			cursorManager.setType(CursorManager.PAUSE);
		} else {
			cursorManager.setType(CursorManager.PLAY);
		}
	};

	private videoOutHandler = e => {
		cursorManager.setType(CursorManager.COMPASS);
	};

	private videoClickHandler = e => {
		if (cursorManager.isDragClick) {
			if (this.playing) {
				this.pauseVideo();
			} else {
				this.playVideo();
			}
		}
	};

	public load() {
		this.posterImage.setAttribute('src', this.posterImage.getAttribute('data-src'));
	}

	private loadVideo() {
		if (!this.player) {
			const iframe = this.element.querySelector('iframe');
			iframe.setAttribute('src', iframe.getAttribute('data-src'));
			this.player = new Player(iframe);
			this.player.on('play', this.videoPlayHandler);
			this.player.on('pause', this.videoPauseHandler);
		}
	}

	public playVideo() {
		if (!this.player) {
			this.loadVideo();
		}
		this.player.play();
	}

	private videoPlayHandler = () => {
		this.playing = true;
		this.posterImage.style.opacity = '0';
		cursorManager.setType(CursorManager.PAUSE);
	};

	public pauseVideo() {
		this.player.pause();
	}

	private videoPauseHandler = () => {
		this.playing = false;
		cursorManager.setType(CursorManager.PLAY);
	};

	public unload() {
		//
	}
}
