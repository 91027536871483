import { Emitter, EVENTS } from '../../utils/emitter';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { Linear, TweenMax } from 'gsap/TweenMax';

export class AudioButton extends Emitter {
	public element: HTMLDivElement;
	public audioElement: HTMLAudioElement;
	private titleElement: HTMLElement;
	private playing: boolean;
	private bars: NodeList;
	private lastCursor: string;

	constructor(element: HTMLDivElement) {
		super();
		this.element = element;
		this.titleElement = element.querySelector('.buttonTitle');
		this.bars = this.element.querySelectorAll('svg rect');
		this.audioElement = this.element.querySelector('audio');
		this.audioElement.addEventListener('ended', this.onAudioEndedHandler);
		this.element.addEventListener('click', this.clickHandler);
		this.element.addEventListener('mouseenter', this.overHandler);
		this.element.addEventListener('mouseleave', this.outHandler);
		this.hide();
	}

	public show() {
		TweenMax.to(this.element, 0.5, { autoAlpha: 1 });
		this.stopAnimateBars();
	}

	public hide() {
		this.pauseAudio();
		TweenMax.to(this.element, 0.5, { autoAlpha: 0 });
	}

	private clickHandler = e => {
		if (this.playing) {
			this.pauseAudio();
		} else {
			this.playAudio();
		}
	};

	private overHandler = e => {
		this.lastCursor = cursorManager.getType();
		cursorManager.setType(CursorManager.POINTER);
	};

	private outHandler = e => {
		cursorManager.setType(this.lastCursor);
	};

	private onAudioEndedHandler = e => {
		this.stopAnimateBars();
	};

	public playAudio() {
		this.playing = true;
		if (this.audioElement.getAttribute('data-src')) {
			this.audioElement.setAttribute('src', this.audioElement.getAttribute('data-src'));
			this.audioElement.removeAttribute('data-src');
			this.audioElement.load();
			this.audioElement.play();
		} else {
			this.audioElement.play();
		}
		this.animateBars();
		this.emit(EVENTS.play, {});
	}

	public pauseAudio() {
		this.playing = false;
		this.audioElement.pause();
		this.stopAnimateBars();
		this.emit(EVENTS.pause, {});
	}

	public showTitle() {
		TweenMax.to(this.titleElement, 0.5, { autoAlpha: 1 });
	}

	public hideTitle() {
		TweenMax.to(this.titleElement, 0.5, { autoAlpha: 0 });
	}

	private animateBars() {
		this.bars.forEach(bar => {
			TweenMax.killTweensOf(bar);
			const scaleY = Math.random() * 0.2 + 0.1;
			const h = scaleY * 14;
			const y = 22 - h / 2;
			TweenMax.set(bar, { scaleY: 1 });
			TweenMax.to(bar, Math.random() * 0.5 + 0.25, {
				yoyo: true,
				repeat: -1,
				ease: Linear.easeNone,
				attr: { height: h, y: y }
			});
		});
	}

	private stopAnimateBars() {
		const barsReset = [0.3, 0.6, 1, 0.6, 0.3];
		this.bars.forEach((bar, index) => {
			TweenMax.killTweensOf(bar);
			const h = barsReset[index] * 14;
			const y = 22 - h / 2;
			TweenMax.to(bar, Math.random() * 0.2 + 0.1, {
				attr: { height: h, y: y }
			});
		});
	}
}
