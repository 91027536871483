import { Power1, Power2, Power3, TweenMax } from 'gsap/TweenMax';
import { landing } from '../../templates/Landing';
import { WindowManager } from '../../utils/WindowManager';
import { mobileProjectNav } from '../Projects/MobileProjectNav';
import { sunDial } from '../Sundial/Sundial';
import { infoSections } from '../../templates/InfoSections';

export class MobileMenu {
	public element: HTMLElement;
	public showing: Boolean;
	private menuItemsEl: HTMLElement;
	private overviewButton: HTMLElement;
	private projectMenuItem: HTMLElement;
	private projectMenuContainer: HTMLElement;
	private projectMenuList: HTMLElement;
	private projectListOpen: boolean = true;
	private infoMenuItems: NodeList;

	constructor() {
		this.element = document.getElementById('MobileMenu');
		this.overviewButton = this.element.querySelector('.overviewButton');
		this.menuItemsEl = this.element.querySelector('.menuItems');
		this.projectMenuItem = this.element.querySelector('.projects > a');
		this.infoMenuItems = this.element.querySelectorAll('.infoLink');
		this.projectMenuContainer = this.element.querySelector('.projectMenuContainer');
		this.projectMenuList = this.element.querySelector('.projectMenuList');
		this.overviewButton.addEventListener('click', this.overviewButtonClickHandler);
		this.projectMenuItem.addEventListener('click', this.projectMenuItemClick);
		this.infoMenuItems.forEach(item => {
			item.addEventListener('click', this.infoMenuItemClick);
		});
	}

	// Set active menu item
	public transitionIn() {}

	private overviewButtonClickHandler = e => {
		e.preventDefault();
		this.toggleShowHide();
	};

	private toggleShowHide() {
		if (this.showing) {
			this.hide();
		} else {
			this.show();
		}
	}

	public show() {
		this.overviewButton.classList.add('active');
		this.showing = true;
		sunDial.hide();
		TweenMax.to(this.menuItemsEl, 0.7, { opacity: 1 });
		TweenMax.to(this.menuItemsEl, 0.7, { y: 0 });
		TweenMax.to([landing.element, mobileProjectNav.element, infoSections.element], 0.7, { y: WindowManager.landingHeight });
	}

	public hide() {
		this.overviewButton.classList.remove('active');
		this.showing = false;
		sunDial.show();
		TweenMax.to(this.menuItemsEl, 0.6, { opacity: 0 });
		TweenMax.to(this.menuItemsEl, 0.6, { y: -WindowManager.landingHeight });
		TweenMax.to([landing.element, mobileProjectNav.element, infoSections.element], 0.6, { y: 0 });
	}

	private projectMenuItemClick = e => {
		e.preventDefault();
		console.log('project men item click', e);
		if (this.projectListOpen) {
			this.closeProjectList();
		} else {
			this.openProjectList();
		}
	};

	private infoMenuItemClick = e => {
		e.preventDefault();
		this.hide();
		infoSections.show(e.currentTarget.getAttribute('data-template'));
	};

	private openProjectList() {
		this.projectListOpen = true;
		TweenMax.to(this.projectMenuContainer, 0.7, {
			height: this.projectMenuList.clientHeight,
			ease: Power2.easeInOut
		});
	}

	private closeProjectList() {
		this.projectListOpen = false;
		TweenMax.to(this.projectMenuContainer, 0.7, {
			height: 0,
			ease: Power2.easeInOut
		});
	}
}

export const mobileMenu = new MobileMenu();
