import {AudioButton} from "../modules/AudioButton/AudioButton";
import {Linear, TweenMax} from "gsap/TweenMax";
import {sec2time} from "../utils/MathHelpers";

export class About {
	public element: HTMLElement;
	private audioButton: AudioButton;
	private playHeadEl: HTMLElement;
	private playDurationEl: HTMLElement;
	private timeEl: HTMLElement;

	constructor(element: HTMLElement) {
		this.element = element;
		this.audioButton = new AudioButton(this.element.querySelector('.AudioButton'));
		this.audioButton.show();
		this.audioButton.audioElement.addEventListener('timeupdate', this.onAudioProgress.bind(this));
		this.playHeadEl = this.element.querySelector('.playhead');
		this.playDurationEl = this.element.querySelector('.playDuration');
		this.timeEl = this.element.querySelector('.time');
		this.setAudioTime(0);
	}

	private onAudioProgress(e) {
		const time = this.audioButton.audioElement.currentTime;
		const audioProgress = time / this.audioButton.audioElement.duration;
		TweenMax.to(this.playHeadEl, .3, {
			x: audioProgress * this.playDurationEl.clientWidth,
			ease: Linear.easeNone
		});
		this.setAudioTime(time);
	}

	private setAudioTime(time) {
		this.timeEl.innerHTML = sec2time(time);
	}

	public pause () {
		this.audioButton.pauseAudio();
	}
}
