import { TweenMax } from 'gsap/TweenMax';
import { emitter, EVENTS } from '../../utils/emitter';

export const MORNING_COLORS = {
	background: '#fee2d7',
	foreground: '#000000',
	blob: '#872d48',
	blob_no_project: '#282828',
	lines: '#e1c6cc'
};

export const MIDDAY_COLORS = {
	background: '#fdfbf2',
	foreground: '#000000',
	blob: '#ffc534',
	blob_no_project: '#282828',
	lines: '#ece4de'
};

export const EVENING_COLORS = {
	background: '#dee9f5',
	foreground: '#000000',
	blob: '#232785',
	blob_no_project: '#282828',
	lines: '#d4dde8'
};

export const NIGHT_COLORS = {
	background: '#272727',
	foreground: '#ffffff',
	blob: '#f3d8b1',
	blob_no_project: '#FFFDF5',
	lines: '#434343'
};

export class ColorThemeManager {
	public currentColorSettings;
	public lastColorSettings;
	private uiElements = document.querySelectorAll('[data-color-type]');

	constructor() {}

	public setColorThemeByTime(timeOfDay: number, speed: number = 0.8) {
		this.setColorTheme(this.timeToColors(timeOfDay), speed);
	}

	public setColorTheme(settings, speed: number = 0.8) {
		this.lastColorSettings = this.currentColorSettings;
		this.currentColorSettings = settings;
		// TweenMax.to(document.body, speed, {color: settings.foreground});

		this.uiElements.forEach(item => {
			const paramsObj = this.propsToColorParams(item.getAttribute('data-color-type'), item.getAttribute('data-color-prop'));
			TweenMax.to(item, speed, paramsObj);
		});

		emitter.emit(EVENTS.colorChange, {
			colors: this.currentColorSettings,
			speed: speed
		});
	}

	private propsToColorParams(colorTypes, colorProps) {
		const colorTypesArr = colorTypes.split(',');
		const colorPropsArr = colorProps.split(',');
		let paramsObj = {};
		for (let i = 0; i < colorTypesArr.length; i++) {
			paramsObj[colorPropsArr[i]] = this.currentColorSettings[colorTypesArr[i]];
		}
		return paramsObj;
	}

	public timeToColors(time) {
		if (time <= 6 || time >= 21) {
			return NIGHT_COLORS;
		} else if (time <= 11) {
			return MORNING_COLORS;
		} else if (time <= 16) {
			return MIDDAY_COLORS;
		} else if (time < 21) {
			return EVENING_COLORS;
		}
	}

	public lightenColor(color, percent) {
		const num = parseInt(color.replace('#', ''), 16),
			amt = Math.round(2.55 * percent),
			R = (num >> 16) + amt,
			B = ((num >> 8) & 0x00ff) + amt,
			G = (num & 0x0000ff) + amt;
		return '#' + (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
	}
}

export const colorThemeManager = new ColorThemeManager();
