import { Elastic, Power3, Sine, TimelineLite, TweenMax } from 'gsap/TweenMax';
import { LinePoint } from './LinePoint';
import { Blob } from './Blob';
import { WindowManager } from '../../utils/WindowManager';
import { Point } from '../../utils/Point';

export class Line {
	public y: number;
	private context: CanvasRenderingContext2D;
	private points: Array<LinePoint>;
	private lineColor: string;
	readonly blobs: Array<Blob>;
	readonly totalPoints: number;

	constructor(context: CanvasRenderingContext2D, y: number, totalPoints: number, blobs: Array<Blob>, color: string) {
		this.context = context;
		this.blobs = blobs;
		this.y = y;
		this.lineColor = color;
		this.totalPoints = totalPoints;
		this.resize();
	}

	public setColor(color: string, speed: number) {
		TweenMax.to(this, speed, { lineColor: color });
	}

	public move(time: number, cursorPoint: Point, cursorSpeed: number = 0) {
		for (let i = 0; i < this.totalPoints - 1; i++) {
			const p = this.points[i];
			p.blob = null;

			for (let j = 0; j < this.blobs.length; j++) {
				let dx = p.ix - this.blobs[j].centerPoint.x / WindowManager.landingWidth;
				let dy = p.iy - this.blobs[j].centerPoint.y / WindowManager.landingHeight;
				let d = Math.sqrt(dx * dx + dy * dy);
				if (d <= 0.3 && this.blobs[j].isRendered) {
					p.blob = this.blobs[j];
					break;
				}
			}
			p.move(time, cursorPoint, cursorSpeed);
			this.points[i + 1].move(time, cursorPoint, cursorSpeed);
			p.cx = ((p.x + this.points[i + 1].x) / 2) * WindowManager.landingWidth;
			p.cy = ((p.y + this.points[i + 1].y) / 2) * WindowManager.landingHeight;
		}
	}

	public render() {
		let p = this.points[0];
		this.context.strokeStyle = this.lineColor;
		this.context.lineWidth = 1;
		this.context.beginPath();
		this.context.moveTo(p.x * WindowManager.width, p.y * WindowManager.landingHeight);

		for (let i = 0; i < this.totalPoints - 1; i++) {
			p = this.points[i];
			this.context.bezierCurveTo(p.x * WindowManager.width, p.y * WindowManager.landingHeight, p.cx, p.cy, p.cx, p.cy);
		}

		this.context.stroke();
	}

	public resize = () => {
		this.points = [];
		for (let i = 0; i < this.totalPoints; i++) {
			const pt = new LinePoint(i / (this.totalPoints - 2), this.y);
			this.points.push(pt);
		}
	};
}
