import {Power1, TweenMax} from "gsap/TweenMax";


export class Latest {
	public element: HTMLElement;
	private prevArrow: HTMLElement;
	private nextArrow: HTMLElement;
	private scrollEl: HTMLElement;
	private newsListingEl: HTMLElement;
	private newsItems: NodeList;
	private filters: NodeList;
	private currentPage: number = 0;
	private itemsPerPage: number = 0;
	private totalEntries: number = 0;
	private totalPages: number = 0;

	constructor(element: HTMLElement) {
		this.element = element;
		this.newsListingEl = this.element.querySelector('.newsListing');
		this.newsItems = this.element.querySelectorAll('.newsItem');
		this.scrollEl = this.element.querySelector('.scroller');
		this.prevArrow = this.element.querySelector('.prev');
		this.nextArrow = this.element.querySelector('.next');
		this.filters = this.element.querySelectorAll('.filter');

		this.filters.forEach((filter => {
			filter.addEventListener('click', this.filterSelectHandler.bind(this));
		}));

		this.prevArrow.addEventListener('click', this.prevHandler.bind(this));
		this.nextArrow.addEventListener('click', this.nextHandler.bind(this));
	}

	resize() {
		this.updateTotalPages();
		this.gotoPage(this.currentPage, 0);
	}

	updateTotalPages() {
		this.totalEntries = 0;
		this.newsItems.forEach((newsItem) => {
			const newsItemEl = newsItem as HTMLElement;
			if (newsItemEl.style.display != 'none') {
				this.totalEntries++;
			}
		});

		this.itemsPerPage = Math.round(this.newsListingEl.clientWidth /
			(this.newsItems[0] as HTMLElement).clientWidth);
		this.totalPages = Math.ceil(this.totalEntries / this.itemsPerPage);
		this.checkArrows();
	}

	prevHandler(e) {
		this.gotoPage(this.currentPage - 1);
	}

	nextHandler(e) {
		this.gotoPage(this.currentPage + 1);
	}

	filterSelectHandler(e) {
		e.preventDefault();
		this.filters.forEach((filter => {
			(filter as HTMLElement).classList.remove('active');
		}));
		e.currentTarget.classList.add('active');
		this.filter(e.currentTarget.getAttribute('data-filter'));
	}

	filter(value) {
		this.newsItems.forEach((newsItem) => {
			const newsItemEl = newsItem as HTMLElement;
			const category = newsItemEl.getAttribute('data-category');
			if (value == category || value == 'all') {
				TweenMax.set(newsItemEl, {'display': 'block', opacity: 0});
				TweenMax.to(newsItemEl, .2, {opacity: 1});
			} else {
				newsItemEl.style.display = 'none';
			}
		});

		this.updateTotalPages();
		this.gotoPage(0, 0);
	}

	gotoPage(index, speed = .8) {
		this.currentPage = index;
		TweenMax.to(this.scrollEl, speed, {
			x: -this.newsListingEl.clientWidth * this.currentPage
		});
		this.checkArrows();
	}

	checkArrows() {
		this.prevArrow.classList.remove('disabled');
		this.nextArrow.classList.remove('disabled');

		if (this.currentPage == this.totalPages - 1) {
			this.nextArrow.classList.add('disabled');
		}
		if (this.currentPage == 0) {
			this.prevArrow.classList.add('disabled');
		}
	}
}
