import {TweenMax} from "gsap/TweenMax";

export class Contact {
	private items: NodeListOf<HTMLAnchorElement>;
	public element;

	constructor(element: HTMLElement) {
		this.element = element;

		this.items = element.querySelectorAll('.contact-socials a');

		this.items.forEach((item) => {
			const hoverArrow = (item as HTMLElement).querySelector('.hoverArrow');
			TweenMax.set(hoverArrow, {x: -15, autoAlpha: 0});
			item.addEventListener('mouseover', (e) => {
				TweenMax.to(hoverArrow, .5, {x: 0, autoAlpha: 1});
			});
			item.addEventListener('mouseout', (e) => {
				TweenMax.to(hoverArrow, .5, {x: -15, autoAlpha: 0});
			});
		});
	}
}
