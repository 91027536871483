import { Power1, TweenMax } from 'gsap/TweenMax';
import { ColorThemeManager } from '../ColorThemeManager/ColorThemeManager';
import { infoSections } from '../../templates/InfoSections';

export class Sundial {
	public element: HTMLDivElement;
	private morningCircleEl: HTMLDivElement;
	private nightCircleEl: HTMLDivElement;
	private maskEl: HTMLDivElement;
	private lineEl: HTMLDivElement;
	private timeEl: HTMLDivElement;
	private time: number = 0;
	private circleDiameter: number;
	private circleRadius: number;
	private centerX: number;
	private centerY: number;
	private xRadius: number;
	private yRadius: number;

	constructor() {}

	public init() {
		this.element = document.querySelector('.Sundial');
		this.morningCircleEl = this.element.querySelector('.circle.morning');
		this.nightCircleEl = this.element.querySelector('.circle.night');
		this.maskEl = this.element.querySelector('.morningMask');
		this.lineEl = this.element.querySelector('.line');
		this.timeEl = this.element.querySelector('.time');
		this.layout();
		this.setTime(0, 0);
		TweenMax.set(this.element, { autoAlpha: 0, y: 30 });
	}

	public setTime(newTime: number, speed: number = 2) {
		let cRotations = Math.floor(this.time / 24);
		if (newTime < this.time % 24) {
			newTime += (cRotations + 1) * 24;
		} else {
			newTime += cRotations * 24;
		}
		TweenMax.to(this, speed, {
			onUpdate: this.update,
			time: newTime,
			ease: Power1.easeInOut
		});
	}

	public layout() {
		this.circleDiameter = this.morningCircleEl.clientWidth;
		this.circleRadius = this.circleDiameter / 2;
		this.yRadius = this.maskEl.clientHeight - this.circleRadius;
		this.xRadius = (this.lineEl.clientWidth - this.circleDiameter) / 2;
		this.centerX = this.xRadius + this.circleRadius;
		this.centerY = this.maskEl.clientHeight;
	}

	public hide() {
		TweenMax.to(this.element, 0.25, {
			autoAlpha: 0,
			y: 30,
			onComplete: () => {
				this.element.style.display = 'none';
			}
		});
	}

	public show() {
		if (infoSections.showing) {
			return;
		}
		TweenMax.to(this.element, 0.25, { autoAlpha: 1, y: 0 });
		this.element.style.display = 'block';
	}

	private update = () => {
		const i = ((this.time + 4) / 24) * (2 * Math.PI);
		const xPos = this.centerX - this.yRadius * Math.sin(i) * Math.sin(0 * Math.PI) + this.xRadius * Math.cos(i) * Math.cos(0 * Math.PI);
		const yPos = this.centerY + this.xRadius * Math.cos(i) * Math.sin(0 * Math.PI) + this.yRadius * Math.sin(i) * Math.cos(0 * Math.PI);
		TweenMax.set([this.morningCircleEl, this.nightCircleEl], { x: xPos, y: yPos });
		this.timeEl.innerHTML = this.numToTime(this.time);
	};

	private numToTime(num) {
		let min = this.prependZero(Math.round((this.time % 1) * 59));
		let hour = this.prependZero(Math.round(this.time % 24));
		return hour + ':' + min;
	}

	private prependZero(num) {
		return num.toString().length < 2 ? '0' + num : num;
	}
}

export const sunDial = new Sundial();
