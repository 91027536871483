import { Blob } from './Blob';
import { WindowManager } from '../../utils/WindowManager';
import { Point } from '../../utils/Point';
import { cursorManager } from '../CursorManager/CursorManager';

export class LinePoint {
	public x: number;
	public y: number;
	public ix: number;
	public iy: number;
	public cx: number;
	public cy: number;
	public blob: Blob;
	public random: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
		this.ix = x;
		this.iy = y;
		this.blob = null;
		this.random = Math.random();
	}

	public move(time: number, cursorPoint: Point, cursorSpeed: number = 0) {
		let cursorY = 0;

		if (cursorPoint) {
			const dx = (this.ix - cursorPoint.x / WindowManager.landingWidth) * (WindowManager.landingWidth / WindowManager.landingHeight); //1 for old eye shape
			const dy = this.iy - cursorPoint.y / WindowManager.landingHeight;
			let d = Math.sqrt(dx * dx + dy * dy);
			cursorY = 0;
			if (d < 0.07) {
				cursorY = (0.07 - (d + (this.random * 0.03 - 0.015))) * Math.sign(dy) * (cursorSpeed / 50);
			}
		}

		if (this.blob) {
			const dx = (this.ix - this.blob.centerPoint.x / WindowManager.landingWidth) * (WindowManager.landingWidth / WindowManager.landingHeight) * 0.7; //1 for old eye shape
			const dy = this.iy - this.blob.centerPoint.y / WindowManager.landingHeight;
			let d = Math.sqrt(dx * dx + dy * dy);
			const a = (this.blob.radius * 1) / WindowManager.landingHeight + (this.random * 0.04 - 0.02);
			d = a - Math.min(a, d);
			this.y = this.iy + d * Math.sign(dy) + cursorY;
		} else {
			this.y = this.iy + cursorY;
		}
	}
}
