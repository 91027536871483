type EventHandler = (event?: any) => void;
type WildCardHandler = (type: string, event?: any) => void;
type EventHandlerList = EventHandler[];
type WildCardHandlerList = WildCardHandler[];
type EventHandlerMap = {
	'*'?: WildCardHandlerList;
	[type: string]: EventHandlerList;
};

export class Emitter {
	private all: EventHandlerMap;

	constructor(all: EventHandlerMap = Object.create(null)) {
		this.all = all;
	}

	/**
	 * Register an event handler for the given type.
	 *
	 * @param  {String} type    Type of event to listen for, or `"*"` for all events
	 * @param  {Function} handler Function to call in response to given event
	 */
	public on(type: string, handler: EventHandler) {
		(this.all[type] || (this.all[type] = [])).push(handler);
	}

	/**
	 * Remove an event handler for the given type.
	 *
	 * @param  {String} type    Type of event to unregister `handler` from, or `"*"`
	 * @param  {Function} handler Handler function to remove
	 */
	public off(type: string, handler: EventHandler) {
		if (this.all[type]) {
			this.all[type].splice(this.all[type].indexOf(handler) >>> 0, 1);
		}
	}

	/**
	 * Invoke all handlers for the given type.
	 * If present, `"*"` handlers are invoked after type-matched handlers.
	 *
	 * @param {String} type  The event type to invoke
	 * @param {Any} [evt]  Any value (object is recommended and powerful), passed to each handler
	 */
	public emit(type: string, evt: any) {
		(this.all[type] || []).slice().map(handler => handler(evt));
		(this.all['*'] || []).slice().map(handler => handler(type, evt));
	}
}

// Define events here to avoid magic strings
export const EVENTS = {
	loaded: 'event_loaded',
	resize: 'event_resize',
	scroll: 'event_scroll',
	customScroll: 'event_custom_scroll',
	select: 'select',
	mouseOver: 'event_mouse_over',
	mouseOut: 'event_mouse_out',
	mouseDown: 'event_mouse_down',
	mouseMove: 'event_mouse_move',
	mouseUp: 'event_mouse_up',
	mouseEnter: 'event_mouse_enter',
	mouseLeave: 'event_mouse_leave',
	dragClick: 'event_drag_click',
	mouse: 'event_mouse',
	tick: 'event_update_tick',
	stateChange: 'event_state_change',
	colorChange: 'event_color_change',
	play: 'event_play',
	pause: 'event_pause',
	fullScreen: 'event_full_screen',
	complete: 'event_complete',
	loop: 'event_loop',
	show: 'event_show',
	enabled: 'event_enabled',
	enterScreen: 'event_enter_screen',
	showSlideshow: 'event_show_slideshow',
	hideSlideshow: 'event_hide_slideshow',
	assetLoadStart: 'event_load_start',
	assetLoadComplete: 'event_load_complete',
	instructionsComplete: 'event_instructions_complete',
	backToStart: 'back_to_start',
	nextModule: 'next_module',
	prevModule: 'prev_module'
};

export const emitter = new Emitter();
