import { projects } from '../Projects/Projects';
import { Power1, Power3, TweenMax } from 'gsap/TweenMax';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { emitter, EVENTS } from '../../utils/emitter';
import { projectMenu } from '../ProjectMenu/ProjectMenu';
import { infoSections } from '../../templates/InfoSections';

export class Menu {
	public element: HTMLElement;
	public backToStartShowing: boolean;
	public hamburgerLines: NodeList;
	private backToStart: HTMLElement;
	private menuItems: NodeListOf<HTMLAnchorElement>;
	private projectMenuButton: HTMLElement;
	private overviewButton: HTMLElement;

	constructor() {
		this.element = document.getElementById('Menu');
		this.menuItems = this.element.querySelectorAll('.menuWrapper a');
		this.backToStart = this.element.querySelector('.backToStart');
		this.hamburgerLines = this.element.querySelectorAll('.hamburger .line');
		this.backToStart.addEventListener('click', this.backToStartHandler);
		this.overviewButton = this.element.querySelector('.overviewButton');
		this.projectMenuButton = this.element.querySelector('.projectMenuButton');

		ScrollToPlugin;

		this.projectMenuButton.addEventListener('click', this.projectMenuButtonClickHandler);

		this.menuItems.forEach(item => {
			item.addEventListener('click', this.navItemClickHandler);
		});
	}

	// Set active menu item
	public transitionIn() {
		TweenMax.staggerTo([this.projectMenuButton, this.menuItems], 1, { y: 0, ease: Power3.easeOut }, 0.15);
	}

	public show() {
		this.element.style.display = 'block';
	}

	public hide() {
		this.element.style.display = 'none';
	}

	public showBackToStart() {
		if (projects.activeProject && projects.activeProject.hasBeenPanned()) {
			this.backToStartShowing = true;
			TweenMax.to(this.backToStart, 0.5, { autoAlpha: 1 });
		}
	}

	public hideBackToStart() {
		this.backToStartShowing = false;
		TweenMax.to(this.backToStart, 0.5, { autoAlpha: 0 });
	}

	public setProjectMenuButtonTitle(value: string) {
		this.projectMenuButton.querySelector('.title').innerHTML = value;
	}

	private backToStartHandler(e) {
		e.preventDefault();
		emitter.emit(EVENTS.backToStart, {});
	}

	private projectMenuButtonClickHandler = e => {
		e.preventDefault();
		projectMenu.toggleShowHide();
	};

	private navItemClickHandler = e => {
		e.preventDefault();
		projectMenu.hide();
		infoSections.show(e.currentTarget.getAttribute('data-template'));
	};

	private async showInfo(sectionId) {}
}

export const menu = new Menu();
