import { TweenMax, Power3, Power2, Power1 } from 'gsap/TweenMax';
import { landing } from '../../templates/Landing';
import { infoSections } from '../../templates/InfoSections';
import { sunDial } from '../Sundial/Sundial';
import { WindowManager } from '../../utils/WindowManager';
import { menu } from '../Menu/Menu';

export class ProjectMenu {
	public element: HTMLElement;
	private items: NodeListOf<HTMLAnchorElement>;
	private showing: boolean;
	private showComplete: boolean;
	private leftColImage: HTMLImageElement;
	private rightColImage: HTMLImageElement;
	private imagesHidden: Boolean = true;

	constructor() {
		this.element = document.querySelector('.ProjectMenu');
		this.items = this.element.querySelectorAll('li a');
		this.leftColImage = this.element.querySelector('.image-left img');
		this.rightColImage = this.element.querySelector('.image-right img');

		this.items.forEach((selectedItem, index) => {
			selectedItem.addEventListener('mouseover', e => {
				if (this.showComplete) {
					this.loadImages(selectedItem.querySelectorAll('.image-data'));
					this.items.forEach((item, index) => {
						if (item != selectedItem) {
							TweenMax.to(item, 0.1, { opacity: 0, overwrite: 'auto' });
						}
					});
					TweenMax.to(selectedItem, 0.4, { opacity: 1 });
					TweenMax.to(selectedItem.querySelector('.itemDescription'), 0.4, { opacity: 1 });
				}
			});
			selectedItem.addEventListener('mouseout', e => {
				if (this.showComplete) {
					this.hideImages();
					TweenMax.to(selectedItem.querySelector('.itemDescription'), 0, { opacity: 0 });
					this.items.forEach((item, index) => {
						if (this.getActiveItem() != item) {
							TweenMax.to(item, 0.4, { opacity: this.getActiveItem() ? 0.2 : 1 });
						}
					});
					TweenMax.to(this.getActiveItem(), 0.4, { opacity: 1 });
				}
			});
			selectedItem.addEventListener('click', () => {
				if (this.getActiveItem() != selectedItem) {
					TweenMax.to(selectedItem.querySelector('.itemDescription'), 0, { opacity: 0 });
				}
			});
		});

		const emailAddress = document.querySelector('.contact.section p.email a').getAttribute('href');
		this.element.querySelector('.contact-email').setAttribute('href', emailAddress);
	}

	public init() {
		this.hide(0);
	}

	public toggleShowHide() {
		if (this.showing) {
			this.hide();
		} else {
			this.show();
		}
	}

	public resize() {
		if (this.showing) {
			TweenMax.set([landing.element, infoSections.element], { y: WindowManager.height });
		} else {
			TweenMax.set(this.element, { y: -WindowManager.height });
		}
	}

	public hide(speed = 0.7) {
		this.showing = false;
		this.showComplete = false;
		sunDial.show();
		this.hideImages();
		menu.setProjectMenuButtonTitle('my work');

		TweenMax.to(this.element, speed / 2, {
			autoAlpha: 0
		});

		TweenMax.to(this.element, speed, {
			y: -WindowManager.height,
			ease: Power2.easeInOut
		});

		TweenMax.to([landing.element, infoSections.element], speed, {
			y: 0,
			ease: Power2.easeInOut
		});
	}

	public show(speed = 0.8) {
		this.showing = true;
		sunDial.hide();
		menu.setProjectMenuButtonTitle('close');

		this.items.forEach((item, index) => {
			TweenMax.set(item, { opacity: 0 });
			const opac = this.getActiveItem() == null ? 1 : item == this.getActiveItem() ? 1 : 0.1;
			TweenMax.to(item, 0.7, { opacity: opac, delay: 0.07 * index + 0.4 });
		});

		TweenMax.to(this.element, speed, {
			autoAlpha: 1,
			ease: Power2.easeInOut,
			y: 0
		});

		TweenMax.to([landing.element, infoSections.element], speed, {
			y: WindowManager.height,
			ease: Power2.easeInOut,
			onComplete: () => {
				this.showComplete = true;
			}
		});
	}

	private getActiveItem() {
		let activeItem = null;
		this.items.forEach(item => {
			if (item.classList.contains('active')) {
				activeItem = item;
			}
		});
		return activeItem;
	}

	public removeActiveStates() {
		this.items.forEach(item => {
			item.classList.remove('active');
		});
	}

	public setState(slug = null) {
		this.items.forEach(item => {
			const hrefSlug = item.href.split('/').slice(-1)[0];
			if (hrefSlug === slug) {
				item.classList.add('active');
			} else {
				item.classList.remove('active');
			}
		});
	}

	private loadImages(images) {
		if (!this.showComplete) {
			return;
		}

		this.imagesHidden = false;
		const leftImgSrc = images[0].dataset.image;
		const rightImgSrc = images[1].dataset.image;

		this.leftColImage.onload = () => {
			if (!this.imagesHidden) {
				TweenMax.to(this.leftColImage, 0.4, { opacity: 1 });
			}
		};

		this.rightColImage.onload = () => {
			if (!this.imagesHidden) {
				TweenMax.to(this.rightColImage, 0.4, { opacity: 1 });
			}
		};

		this.leftColImage.setAttribute('src', leftImgSrc);
		this.rightColImage.setAttribute('src', rightImgSrc);

		this.leftColImage.style.top = this.leftColImage.style.bottom = 'auto';
		this.rightColImage.style.top = this.rightColImage.style.bottom = 'auto';

		const topOrBotLeft = Math.random() < 0.5 ? 'top' : 'bottom';
		this.leftColImage.style[topOrBotLeft] = Math.round(Math.random() * 30) + '%';

		const topOrBotRight = Math.random() < 0.5 ? 'top' : 'bottom';
		this.rightColImage.style[topOrBotRight] = Math.round(Math.random() * 30) + '%';
	}

	private hideImages() {
		this.imagesHidden = true;
		TweenMax.to(this.leftColImage, 0, { opacity: 0, overwrite: 'auto' });
		TweenMax.to(this.rightColImage, 0, { opacity: 0, overwrite: 'auto' });
		this.leftColImage.setAttribute('src', '');
		this.rightColImage.setAttribute('src', '');
	}
}

export const projectMenu = new ProjectMenu();
