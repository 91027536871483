import {Linear, Power1, TweenMax} from "gsap/TweenMax";
import {emitter, EVENTS} from "../utils/emitter";

export class LoaderScreen {

	public progress: number = 0;
	private loader: HTMLElement;
	private element: HTMLElement;
	private leftArrow: HTMLElement;
	private rightArrow: HTMLElement;
	private dragIcon: HTMLElement;
	private dragIconDot: HTMLElement;
	private instructions: HTMLElement;

	constructor(element: HTMLElement) {
		this.element = element;
		this.instructions = this.element.querySelector('.instructions');
		this.leftArrow = this.element.querySelector('.left');
		this.rightArrow = this.element.querySelector('.right');
		this.dragIcon = this.element.querySelector('.drag');
		this.dragIconDot = this.element.querySelector('.drag .dot')
		this.loader = element.querySelector('.loader');
	}

	public update(progress) {
		const progressEl = this.loader.querySelector('.progress');
		const line = this.loader.querySelector('.line') as HTMLDivElement;
		progressEl.innerHTML = String(progress);
		TweenMax.set(line, {width: progress});
	}

	// public showInstructions() {
	// 	this.hideLoaderBar(1);
	// 	TweenMax.delayedCall(2, this.playInstructions, null, this);
	// 	TweenMax.to(this.instructions, 1, {autoAlpha: 1, delay: 2});
	// 	this.hideInstructions(7);
	// }
	//
	// public hideInstructions(delay) {
	// 	TweenMax.to(this.instructions, .6, {
	// 		autoAlpha: 0,
	// 		// scaleX: .8,
	// 		// scaleY: .8,
	// 		delay: delay,
	// 		onComplete: () => {
	// 			this.hide();
	// 		}});
	// }

	public hide(delay = 1) {
		TweenMax.killAll();

		TweenMax.to(this.element, .6, {autoAlpha: 0, delay: delay, onComplete: () => {
			emitter.emit(EVENTS.instructionsComplete, {})
		}});
	}

	private hideLoaderBar(delay) {
		TweenMax.to(this.loader, .5, {autoAlpha: 0, delay: delay});
	}

	private playInstructions() {
		TweenMax.to(this.dragIcon, .6, {x: -50});
		TweenMax.to(this.dragIcon, 1.2, {x: 50, delay: .8, yoyo: true, repeat: -1, ease: Power1.easeInOut, repeatDelay: .2});
		TweenMax.to(this.dragIconDot, .6, {scaleX: .7, scaleY: .7, transformOrigin: 'center', delay: .8, yoyo: true, repeatDelay: .1, repeat: -1, ease: Power1.easeInOut});
		TweenMax.to(this.leftArrow, .3, {opacity: 0, yoyo: true, repeat: -1, repeatDelay: 1});
		TweenMax.to(this.rightArrow, .3, {opacity: 0, yoyo: true, repeat: -1, delay: 1.4, repeatDelay: 1});
	}
}
