/**
 * Created by Lasse on 2/10/2018.
 */
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { distance } from './MathHelpers';

export class WindowManager {
	public static BREAKPOINT_MOBILE = 600;
	public static BREAKPOINT_TABLET_PORTRAIT = 768;
	public static BREAKPOINT_TABLET = 1024;
	public static BREAKPOINT_DESKTOP = 1440;
	public static mobileDisplay: boolean;
	public static landingElement: HTMLDivElement;

	public static signalResize: Signal = new Signal();
	public static width: number;
	public static height: number;
	public static halfWidth: number;
	public static halfHeight: number;
	public static landingWidth: number;
	public static landingHeight: number;
	public static screenWidth: number;
	public static screenHeight: number;
	public static landingHalfWidth: number;
	public static landingHalfHeight: number;
	public static distanceFromCenterToCorner: number;
	public static documentWidth: number;
	public static documentHeight: number;
	public static scaleFactor: number;
	public static previousWidth: number;
	public static previousHeight: number;

	private static instance: WindowManager;

	private constructor() {
		WindowManager.landingElement = document.querySelector('.Landing');
		window.addEventListener('resize', (event: Event) => this.resizeHandler(event));
		this.resizeHandler();
	}

	public static getInstance(): WindowManager {
		if (!WindowManager.instance) {
			WindowManager.instance = new WindowManager();
		}

		return WindowManager.instance;
	}

	public static aspectRatio(): number {
		return WindowManager.width / WindowManager.height;
	}

	public static isMobile(): boolean {
		return WindowManager.width <= WindowManager.BREAKPOINT_MOBILE;
	}

	public sendFakeResizeEvent(): void {
		this.resizeHandler();
	}

	private resizeHandler(event?: Event): void {
		WindowManager.previousWidth = WindowManager.width;
		WindowManager.previousHeight = WindowManager.height;
		WindowManager.width = window.innerWidth;
		WindowManager.height = window.innerHeight;

		WindowManager.screenWidth = screen.width;
		WindowManager.screenHeight = screen.height;

		WindowManager.halfWidth = Math.round(WindowManager.width * 0.5);
		WindowManager.halfHeight = Math.round(WindowManager.height * 0.5);

		WindowManager.documentWidth = document.documentElement.clientWidth;
		WindowManager.documentHeight = document.documentElement.clientHeight;

		WindowManager.landingWidth = WindowManager.landingElement.clientWidth;
		WindowManager.landingHeight = WindowManager.landingElement.clientHeight;

		WindowManager.landingHalfWidth = Math.round(WindowManager.landingWidth * 0.5);
		WindowManager.landingHalfHeight = Math.round(WindowManager.landingHeight * 0.5);

		WindowManager.scaleFactor = WindowManager.width / 1440;

		WindowManager.distanceFromCenterToCorner = distance(WindowManager.halfWidth, WindowManager.halfHeight, WindowManager.width, WindowManager.height);

		WindowManager.signalResize.dispatch(WindowManager.width, WindowManager.height);

		WindowManager.mobileDisplay = WindowManager.width < WindowManager.BREAKPOINT_MOBILE ? true : false;
	}
}
