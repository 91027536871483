import {Power1, TweenMax} from "gsap/TweenMax";

export class Press {
	public element: HTMLElement;
	private pressItems: NodeList;

	constructor(element: HTMLElement) {
		this.element = element;
		this.pressItems = this.element.querySelectorAll('.pressItem');
		this.pressItems.forEach((pressItem) => {
			(pressItem as HTMLElement).querySelector('.topRow').addEventListener('click', this.onTopRowClickHandler);
		});
	}

	onTopRowClickHandler = (e) => {
		const pressItem = e.currentTarget.parentElement.parentElement;
		this.togglePressItem( pressItem);
	}

	private togglePressItem(pressItem) {
		if (pressItem.classList.contains('open')) {
			this.closePressItem(pressItem);
		} else {
			this.openPressItem(pressItem);
		}
	}

	private openPressItem(pressItem) {
		pressItem.classList.add('open');
		const height = pressItem.querySelector('.contents').clientHeight;
		TweenMax.to(pressItem, .5,{height: height});
	}

	private closePressItem(pressItem) {
		pressItem.classList.remove('open');
		const height = pressItem.querySelector('.topRow').clientHeight;
		TweenMax.to(pressItem, .3,{height: height});
	}
}
