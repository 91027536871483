import {CanvasView, canvasView} from "../modules/CanvasView/CanvasView";
import {projects} from "../modules/Projects/Projects";
import {CursorManager, cursorManager} from "../modules/CursorManager/CursorManager";
import {emitter, EVENTS} from "../utils/emitter";
import {Power1, TweenMax} from "gsap/TweenMax";
import {sunDial} from "../modules/Sundial/Sundial";
import {ImageModule} from "../modules/Projects/ImageModule";
import {colorThemeManager} from "../modules/ColorThemeManager/ColorThemeManager";
import {device} from "../utils/device";
import {mobileProjectNav} from "../modules/Projects/MobileProjectNav";
import {ProjectModule} from "../modules/Projects/ProjectModule";
import {Point} from "../utils/Point";

export class Landing {

	public element: HTMLElement;
	public currentSlideshow: ImageModule;
	public paused: boolean;
	private draggingEnabled: boolean = true;
	private lastCursor;

	constructor() {
		this.element = document.querySelector('.Landing');
	}

	public init() {
		projects.init();
		canvasView.init();
		this.addEvents();
	}

	private addEvents() {
		cursorManager.on(EVENTS.mouseMove, (point) => {
			if (cursorManager.dragging) {
				if (this.draggingEnabled) {
					const accel = device.isTouch() ? 2 : 1;
					const delta = {
						x :(point.x - cursorManager.lastTargetMousePoint.x) * accel,
						y: (point.y - cursorManager.lastTargetMousePoint.y) * accel
					};
					canvasView.panBy(delta);
					projects.panBy(delta);
				}
			}
			if (!cursorManager.dragging || device.isTouch()) {
				if (projects.activeProject) {
					projects.updateCompass(point);
				}
			}
		});

		this.element.addEventListener('mouseenter', (event) => {
			if (this.lastCursor) {
				cursorManager.setType(this.lastCursor);
			}
		});

		this.element.addEventListener('mouseleave', (event) => {
			this.lastCursor = cursorManager.getType();
			cursorManager.setType(CursorManager.POINTER);
		});

		emitter.on(EVENTS.backToStart, this.backToStart);

		mobileProjectNav.on(EVENTS.prevModule, this.previousProjectModuleHandler.bind(this));
		mobileProjectNav.on(EVENTS.nextModule, this.nextProjectModuleHandler.bind(this));
	}

	public render = () => {
		if (!this.paused) {
			projects.render();
			canvasView.render();
		}
		requestAnimationFrame(this.render);
	}

	public resize() {
		canvasView.resize();
		projects.resize();

		if (this.currentSlideshow) {
			this.scrollToProjectModule(this.currentSlideshow);
		}
	}

	public transitionIn() {
		canvasView.transitionIn();
	}

	private scrollToProjectModule(module: ProjectModule, offsetPoint: Point = {x: 0, y: 0}) {
		const project = projects.activeProject;
		if (!project) {
			return;
		}
		const modulePosition = module.getCenter();
		const pannedDistance = project.getTargetPannedDistance();
		const initPosition = project.getInitialPosition();
		const delta = {
			x: -pannedDistance.x - initPosition.x - modulePosition.x + offsetPoint.x,
			y: -pannedDistance.y - initPosition.y - modulePosition.y+ offsetPoint.y
		};
		canvasView.panBy(delta);
		projects.activeProject.panBy(delta, true);
	}

	public showProject(slug) {
		const blob = canvasView.getBlobBySlug(slug);
		blob.activate();
		canvasView.idleSpeed = {x: 0, y: 0};
		canvasView.panBy(blob.getDistanceToAnchor());
		projects.showProject(blob.slug);
		const activeProject = projects.activeProject;
		const blobAnchor = blob.getLoopPosition(blob.getPanPosition());
		const activeBlobRadius = blob.getActiveRadius();
		activeProject.setAnchor(blobAnchor);
		activeProject.setPanPosition({
			x: blobAnchor.x + activeBlobRadius.x + activeProject.projectMargin.x + blob.infoEl.clientWidth,
			y: blobAnchor.y + activeBlobRadius.y + activeProject.projectMargin.y
		});
		canvasView.easeModifier = 12;
		TweenMax.to(canvasView, 1, {easeModifier: 1, ease: Power1.easeInOut});
		projects.offsetIntersectingBlobs(canvasView.blobs, true);
	}

	public hideProject() {
		if (!projects.activeProject) {
			return;
		}
		const blob = canvasView.getBlobBySlug(projects.activeProject.slug);
		blob.deactivate();
		projects.hideProject();
		canvasView.showAllBlobs();
		canvasView.idleSpeed = {x: CanvasView.MAX_IDLE_SPEED_X, y: CanvasView.MAX_IDLE_SPEED_Y};
	}

	private backToStart() {
		const dis = projects.activeProject.getTargetPannedDistance();
		const delta = {x: -dis.x, y: -dis.y};
		canvasView.easeModifier = projects.activeProject.easeModifier = 12;
		TweenMax.to([canvasView, projects.activeProject], 1, {easeModifier: 1, ease: Power1.easeInOut});
		canvasView.panBy(delta);
		projects.panBy(delta);
	}

	public backToOverview() {
		const delta = {x: Math.random() * 300 - 150, y: Math.random() * 300 - 150 };
		canvasView.panBy(delta);
		projects.panBy(delta);

		const hours = new Date().getHours();
		colorThemeManager.setColorThemeByTime(hours);
		sunDial.setTime(hours, 0);
	}

	private previousProjectModuleHandler(e) {
		projects.activeProject.setModuleIndex(projects.activeProject.currentModuleIndex - 1);
		this.scrollToProjectModule(projects.activeProject.getCurrentModule(), {x: 0, y: -100});
	}

	private nextProjectModuleHandler(e) {
		projects.activeProject.setModuleIndex(projects.activeProject.currentModuleIndex + 1);
		this.scrollToProjectModule(projects.activeProject.getCurrentModule(), {x: 0, y: -100});
	}

	public showSlideshow(imageModule: ImageModule) {
		this.currentSlideshow = imageModule;
		this.draggingEnabled = false;
		sunDial.hide();
		this.scrollToProjectModule(this.currentSlideshow, {x: 0, y: device.isTouch() ? -100 : -40});
		projects.showSlideshow(imageModule);
		imageModule.showSlideshow();
	}

	public hideSlideshow() {
		if (this.currentSlideshow) {
			this.draggingEnabled = true;
			this.currentSlideshow.hideSlideshow();
			sunDial.show();
			projects.hideSlideshow();
			this.currentSlideshow = null;
		}
	}

	public kill() {

	}
}

export const landing = new Landing();
