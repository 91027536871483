import 'lazysizes';
import { WindowManager } from './utils/WindowManager';
import { landing } from './templates/Landing';
import { detect } from 'detect-browser';
import { menu } from './modules/Menu/Menu';
import { mobileMenu } from './modules/MobileMenu/MobileMenu';
import { ColorThemeManager, colorThemeManager, EVENING_COLORS, MIDDAY_COLORS, MORNING_COLORS, NIGHT_COLORS } from './modules/ColorThemeManager/ColorThemeManager';
import { TweenMax } from 'gsap/TweenMax';
import { router } from './utils/router';
import { State } from './utils/State';
import { cursorManager } from './modules/CursorManager/CursorManager';
import { emitter, EVENTS } from './utils/emitter';
import { sunDial } from './modules/Sundial/Sundial';
import { projects } from './modules/Projects/Projects';
import { LoaderScreen } from './templates/LoaderScreen';
import { infoSections, InfoSections } from './templates/InfoSections';
import { projectMenu } from './modules/ProjectMenu/ProjectMenu';

declare global {
	interface Window {
		Main: Main;
	}
}

class Main {
	public BROWSER = detect();
	private loaderScreen = new LoaderScreen(document.body.querySelector('.LoaderScreen'));

	constructor() {
		sunDial.init();
		WindowManager.getInstance();
		this.initSite();
	}

	private initSite() {
		landing.init();
		infoSections.init();
		projectMenu.init();

		const hours = new Date().getHours();
		colorThemeManager.setColorThemeByTime(hours, 0);
		sunDial.setTime(hours, 0);

		this.addEvents();
		this.resize();

		//projects.load();
		this.loaderScreen.hide();
		landing.render();

		TweenMax.to(this.loaderScreen, 1, {
			progress: 100,
			onUpdate: () => {
				this.loaderScreen.update(Math.round(this.loaderScreen.progress));
			}
		});
	}

	private addEvents() {
		WindowManager.signalResize.add(this.resize);

		// emitter.on(EVENTS.assetLoadStart, this.assetLoadStart);
		// emitter.on(EVENTS.assetLoadComplete, this.assetLoadComplete);
		emitter.on(EVENTS.showSlideshow, this.showSlideshowHandler);
		emitter.on(EVENTS.hideSlideshow, this.hideSlideshowHandler);
		emitter.on(EVENTS.instructionsComplete, this.instructionsComplete.bind(this));
	}

	// private assetLoadStart = e => {
	// 	State.assetsToLoad.push(e.asset);
	// };

	// private assetLoadComplete = e => {
	// 	State.loadedAssets.push(e.asset);
	// 	this.loaderScreen.update(Math.round((State.loadedAssets.length / State.assetsToLoad.length) * 100));
	// 	if (State.assetsToLoad.length == State.loadedAssets.length) {
	// 		//this.loaderScreen.showInstructions();
	// 		this.loaderScreen.hide();
	// 		landing.render();
	// 	}
	// };

	private async instructionsComplete() {
		cursorManager.init();
		landing.transitionIn();
		this.initRouter();
		menu.transitionIn();
		sunDial.show();
		// TweenMax.delayedCall(3, colorThemeManager.setColorTheme, [MORNING_COLORS, 3],  colorThemeManager);
		// TweenMax.delayedCall(3, sunDial.setTime, [8, 3],  sunDial);
		// TweenMax.delayedCall(9, colorThemeManager.setColorTheme, [MIDDAY_COLORS, 3],  colorThemeManager);
		// TweenMax.delayedCall(9, sunDial.setTime, [12, 3],  sunDial);
		// TweenMax.delayedCall(15, colorThemeManager.setColorTheme, [EVENING_COLORS, 3],  colorThemeManager);
		// TweenMax.delayedCall(15, sunDial.setTime, [18, 3],  sunDial);
		// TweenMax.delayedCall(21, colorThemeManager.setColorTheme, [NIGHT_COLORS, 3],  colorThemeManager);
		// TweenMax.delayedCall(21, sunDial.setTime, [23, 3],  sunDial);
	}

	private initRouter() {
		/*
		Define all application routes here.
		*/
		router
			.on('/', () => {
				State.currentView = { name: 'landing' };
				projectMenu.hide();
				projectMenu.setState();
				infoSections.hide();
				mobileMenu.hide();

				this.hideSlideshowHandler(null);
				if (projects.activeProject) {
					menu.hideBackToStart();
					landing.hideProject();
					landing.backToOverview();
				}
			})
			.on('/project/:slug', params => {
				State.currentView = { name: 'project' };
				if (projects.activeProject && projects.activeProject.slug == params.slug) {
					return;
				}
				projectMenu.hide();
				projectMenu.setState(params.slug);
				infoSections.hide();
				mobileMenu.hide();

				this.trackPageView(params.slug);

				this.hideSlideshowHandler(null);
				landing.hideProject();
				landing.showProject(params.slug);
			});
		router.listen();
	}

	private showSlideshowHandler = e => {
		menu.hide();
		landing.showSlideshow(e.instance);
		TweenMax.to(document.body, 0.7, {
			color: '#ffffff',
			delay: 0.7
		});
		TweenMax.to([menu.hamburgerLines], 0.7, {
			backgroundColor: '#ffffff',
			delay: 0.7
		});
		TweenMax.to(cursorManager.pointerCursorDot, 0.7, {
			fill: '#ffffff',
			delay: 0.7
		});
	};

	private hideSlideshowHandler = e => {
		if (landing.currentSlideshow) {
			menu.show();
			landing.hideSlideshow();
			TweenMax.to(document.body, 0.7, {
				color: colorThemeManager.currentColorSettings.foreground
			});
			TweenMax.to([menu.hamburgerLines], 0.7, {
				backgroundColor: colorThemeManager.currentColorSettings.foreground
			});
			TweenMax.to(cursorManager.pointerCursorDot, 0.7, {
				fill: '#000000',
				delay: 0.7
			});
		}
	};

	public resize = () => {
		landing.resize();
		projectMenu.resize();
		infoSections.resize();
	};

	private trackPageView(url: string) {
		(window as any).gtag('config', (window as any).gtagId, { page_path: '/' + url });
	}
}

(window as any).Main = new Main();
