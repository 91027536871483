import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { TweenMax } from 'gsap/TweenMax';
import { ProjectModule } from './ProjectModule';

export class CollaboratorsModule extends ProjectModule {
	private listElement: HTMLUListElement;
	private items: NodeList;

	constructor(element: HTMLDivElement) {
		super(element);
		this.listElement = this.element.querySelector('ul') as HTMLUListElement;
		this.listElement.addEventListener('mouseenter', this.overHandler);
		this.listElement.addEventListener('mouseleave', this.outHandler);

		this.items = this.element.querySelectorAll('li');
		this.items.forEach(item => {
			item.addEventListener('click', this.itemClickHandler);
		});
	}
	private overHandler = e => {
		cursorManager.setType(CursorManager.POINTER);
	};

	private outHandler = e => {
		cursorManager.setType(CursorManager.COMPASS);
	};

	private itemClickHandler = e => {
		const itemEl = e.currentTarget;
		const itemState = itemEl.getAttribute('data-state');
		const arrow = itemEl.querySelector('.arrow');
		const closedHeight = 35;
		let targetHeight;

		if (itemState == 'open') {
			TweenMax.to(arrow, 0.3, { opacity: 0 });
			itemEl.classList.remove('open');
			itemEl.setAttribute('data-state', 'closed');
			targetHeight = closedHeight;
		} else {
			TweenMax.to(arrow, 0.3, { opacity: 1 });
			itemEl.setAttribute('data-state', 'open');
			itemEl.classList.add('open');
			itemEl.style.height = 'auto';
			targetHeight = itemEl.clientHeight;
			itemEl.style.height = closedHeight + 'px';
		}

		TweenMax.to(itemEl, 0.3, { height: targetHeight });
	};
}
