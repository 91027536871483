import { Linear, Power1, TweenMax } from 'gsap/TweenMax';
import { ImageModule } from './ImageModule';
import { ExhibitionModule } from './ExhibitionModule';
import { VideoModule } from './VideoModule';
import { VideoLoopModule } from './VideoLoopModule';
import { Point } from '../../utils/Point';
import { emitter, EVENTS } from '../../utils/emitter';
import { WindowManager } from '../../utils/WindowManager';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { CollaboratorsModule } from './CollaboratorsModule';
import { colorThemeManager } from '../ColorThemeManager/ColorThemeManager';
import { sunDial } from '../Sundial/Sundial';
import { IntroductionModule } from './IntroductionModule';
import { device } from '../../utils/device';
import { canvasView } from '../CanvasView/CanvasView';
import { Blob } from '../CanvasView/Blob';
import { AudioButton } from '../AudioButton/AudioButton';
import { lerp, mod } from '../../utils/MathHelpers';
import { ProjectModule } from './ProjectModule';
import { mobileProjectNav } from './MobileProjectNav';
import { menu } from '../Menu/Menu';

export class Project {
	public element: HTMLDivElement;
	public innerWrapper: HTMLDivElement;
	public slug: string;
	public projectModuleElements: Array<HTMLDivElement>;
	public projectModuleInnerWrappers: Array<HTMLDivElement>;
	public exhibitionTime: number;
	public easeModifier: number = 1;
	public blob: Blob;
	public projectModules: Array<ProjectModule> = [];
	public introductionModules: Array<IntroductionModule> = [];
	public transitioning = false;
	public projectMargin: Point = { x: device.isTouch() ? 100 : 170, y: 100 };
	public currentModuleIndex: number = -1;

	private time = 0;
	private panPosition: Point = { x: 0, y: 0 };
	private easedPanPosition: Point = { x: 0, y: 0 };
	private anchorPosition: Point = { x: 0, y: 0 };
	private initialPosition: Point = { x: 0, y: 0 };
	private nextModuleRect: ClientRect;
	private imageModules: Array<ImageModule> = [];
	private videoModules: Array<VideoModule> = [];
	private videoLoopModules: Array<VideoLoopModule> = [];
	private exhibitionModules: Array<ExhibitionModule> = [];
	private collaboratorsModule: CollaboratorsModule;
	private easeAmount: number = device.isTouch() ? 5 : 10;
	private resizePoint: Point = { x: 0, y: 0 };
	private lastResizePoint: Point = { x: 0, y: 0 };
	private commentaryButtonPosition: number = 0;
	private backToStart: HTMLElement;
	private backToStartButton: HTMLElement;
	private loaded: boolean;

	readonly commentaryButton: AudioButton;

	constructor(element: HTMLDivElement) {
		this.element = element;
		this.innerWrapper = this.element.querySelector('.innerWrapper');
		this.slug = this.element.getAttribute('data-slug');
		this.exhibitionTime = parseInt(this.element.getAttribute('data-exhibition-time'));

		const commentaryButtonEl = this.element.querySelector('.AudioButton') as HTMLDivElement;
		if (commentaryButtonEl) {
			this.commentaryButton = new AudioButton(commentaryButtonEl);
			this.commentaryButton.on(EVENTS.play, this.onAudioPlayHandler.bind(this));
			this.commentaryButton.on(EVENTS.pause, this.onAudioPauseHandler.bind(this));
		}

		this.backToStart = this.element.querySelector('.backToStart');
		this.backToStartButton = this.backToStart.querySelector('.wrapper');

		this.backToStartButton.addEventListener('mouseenter', e => {
			TweenMax.to(this.backToStartButton.querySelector('svg'), 0.5, { x: -10 });
			cursorManager.setType(CursorManager.POINTER);
		});

		this.backToStartButton.addEventListener('mouseleave', e => {
			TweenMax.to(this.backToStartButton.querySelector('svg'), 0.5, { x: 0 });
			cursorManager.setType(CursorManager.COMPASS);
		});

		this.backToStartButton.addEventListener('click', e => {
			e.preventDefault();
			emitter.emit(EVENTS.backToStart, {});
		});

		//

		this.parseModules();
	}

	private parseModules() {
		this.projectModuleElements = Array.from(this.element.querySelectorAll('.projectModule'));
		this.projectModuleInnerWrappers = Array.from(this.element.querySelectorAll('.projectModule .innerWrapper'));

		this.projectModuleElements.forEach(projectModuleElement => {
			let projectModule;

			if (projectModuleElement.classList.contains('IntroductionModule')) {
				projectModule = new IntroductionModule(projectModuleElement);
				this.introductionModules.push(projectModule);
			} else if (projectModuleElement.classList.contains('ImageModule')) {
				projectModule = new ImageModule(projectModuleElement);
				this.imageModules.push(projectModule);
			} else if (projectModuleElement.classList.contains('QuoteModule')) {
				projectModule = new ProjectModule(projectModuleElement);
			} else if (projectModuleElement.classList.contains('ExhibitionModule')) {
				projectModule = new ExhibitionModule(projectModuleElement);
				this.exhibitionModules.push(projectModule);
			} else if (projectModuleElement.classList.contains('VideoModule')) {
				projectModule = new VideoModule(projectModuleElement);
				this.videoModules.push(projectModule);
			} else if (projectModuleElement.classList.contains('VideoLoopModule')) {
				projectModule = new VideoLoopModule(projectModuleElement);
				this.videoLoopModules.push(projectModule);
			} else if (projectModuleElement.classList.contains('CollaboratorsModule')) {
				projectModule = new CollaboratorsModule(projectModuleElement);
				this.collaboratorsModule = projectModule;
			}

			this.projectModules.push(projectModule);
		});
	}

	private onAudioPlayHandler() {
		this.commentaryButton.hideTitle();
		TweenMax.to(this, 1, { commentaryButtonPosition: 1, ease: Power1.easeInOut });
	}

	private onAudioPauseHandler() {
		this.commentaryButton.showTitle();
		TweenMax.to(this, 1, { commentaryButtonPosition: 0, ease: Power1.easeInOut });
	}

	public transitionIn(fadeDelay) {
		this.transitioning = true;
		this.element.style.display = 'block';
		this.blob = canvasView.getBlobBySlug(this.slug);
		this.setModuleIndex(-1);
		mobileProjectNav.show();

		TweenMax.to(this.element, 1, {
			opacity: 1,
			delay: fadeDelay,
			onComplete: () => {
				this.transitioning = false;
				this.load();
				this.setNextClientRect();
			}
		});

		if (this.commentaryButton) {
			this.commentaryButton.show();
		}

		cursorManager.setType(CursorManager.COMPASS);

		sunDial.setTime(this.exhibitionTime);
		TweenMax.delayedCall(1.6, () => {
			colorThemeManager.setColorThemeByTime(this.exhibitionTime);
			// emitter.emit(EVENTS.showSlideshow, { instance: this.imageModules[0] });
		});

		const origin = this.getOrigin();
		this.lastResizePoint.x = this.resizePoint.x = origin.x - (canvasView.entireCanvasSize.width - WindowManager.landingWidth) / 2;
		this.lastResizePoint.y = this.resizePoint.y = origin.y - (canvasView.entireCanvasSize.height - WindowManager.landingHeight) / 2;

		this.layout();
		this.panBy({ x: 0, y: 0 });
		this.setNextClientRect();
	}

	public transitionOut() {
		mobileProjectNav.hide();
		this.commentaryButton.pauseAudio();
		TweenMax.to(this.element, 0.5, {
			opacity: 0,
			onComplete: () => {
				this.unload();
				this.element.style.display = 'none';
			}
		});
	}

	public layout() {
		const checkDirectionEvery = Math.floor(Math.random() * 2) + 2;
		let direction = 1;
		let y = 0;
		let height = 0;
		this.projectModuleElements.map((item, index) => {
			TweenMax.set(item, { marginTop: y });
			if (index % checkDirectionEvery && index != 0) {
				direction = Math.sign(Math.random() - 0.5);
			}
			height = index == 0 ? this.introductionModules[0].innerWrapper.clientHeight : device.isTouch() ? WindowManager.landingHeight * 0.25 : WindowManager.landingHeight * 0.35;
			if (index < this.projectModuleElements.length - 1) {
				y += direction * height;
			}
		});
		TweenMax.set(this.backToStart, { marginTop: y + 200 });
	}

	public resize() {
		this.lastResizePoint.x = this.resizePoint.x;
		this.lastResizePoint.y = this.resizePoint.y;

		const origin = this.getOrigin();
		this.resizePoint.x = origin.x - (canvasView.entireCanvasSize.width - WindowManager.landingWidth) / 2;
		this.resizePoint.y = origin.y - (canvasView.entireCanvasSize.height - WindowManager.landingHeight) / 2;

		const dx = this.resizePoint.x - this.lastResizePoint.x;
		const dy = this.resizePoint.y - this.lastResizePoint.y;
		this.panBy({ x: dx, y: dy });
	}

	public render() {
		this.time += 0.02;
		this.easedPanPosition.x += (this.panPosition.x - this.easedPanPosition.x) / (this.easeModifier * this.easeAmount);
		this.easedPanPosition.y += (this.panPosition.y - this.easedPanPosition.y) / (this.easeModifier * this.easeAmount);
		TweenMax.set(this.innerWrapper, {
			x: Math.round(this.easedPanPosition.x),
			y: Math.round(this.easedPanPosition.y)
		});

		if (this.commentaryButton) {
			const pannedDis = this.getPannedDistance();
			const x = lerp(this.anchorPosition.x + pannedDis.x + this.blob.getActiveRadius().x, 20, this.commentaryButtonPosition);
			const y = lerp(this.anchorPosition.y + pannedDis.y + 220, WindowManager.halfHeight - 100, this.commentaryButtonPosition);
			TweenMax.set(this.commentaryButton.element, { x: x, y: y + Math.sin(this.time) * (20 * (1 - this.commentaryButtonPosition)) });
		}
	}

	public load() {
		if (this.loaded) {
			return;
		}

		this.loaded = true;

		this.introductionModules.forEach(item => {
			item.load();
		});

		this.imageModules.forEach(item => {
			item.load();
		});

		this.exhibitionModules.forEach(item => {
			item.load();
		});

		this.videoModules.forEach(item => {
			item.load();
		});

		this.videoLoopModules.forEach(item => {
			item.load();
		});
	}

	public setPanPosition(position: Point) {
		this.initialPosition.x = this.panPosition.x = this.easedPanPosition.x = position.x;
		this.initialPosition.y = this.panPosition.y = this.easedPanPosition.y = position.y;
		this.render();
	}

	public getPanPosition() {
		return this.panPosition;
	}

	public setAnchor(position: Point) {
		this.anchorPosition = position;
	}

	public getAnchor() {
		return this.anchorPosition;
	}

	public getInitialPosition() {
		return this.initialPosition;
	}

	public getOrigin() {
		return {
			x: this.blob.originPoint.x * canvasView.entireCanvasSize.width,
			y: this.blob.originPoint.y * canvasView.entireCanvasSize.height
		};
	}

	public getPannedDistance() {
		return {
			x: this.easedPanPosition.x - this.initialPosition.x,
			y: this.easedPanPosition.y - this.initialPosition.y
		};
	}

	public getTargetPannedDistance() {
		return {
			x: this.panPosition.x - this.initialPosition.x,
			y: this.panPosition.y - this.initialPosition.y
		};
	}

	public hasBeenPanned() {
		const dis = this.getTargetPannedDistance();
		return Math.round(Math.abs(dis.x)) == 0 && Math.round(Math.abs(dis.y)) == 0 ? false : true;
	}

	public getModuleRect(wrapper) {
		return {
			x: this.panPosition.x + wrapper.parentElement.offsetLeft,
			y: this.panPosition.y + wrapper.parentElement.offsetTop,
			width: wrapper.clientWidth,
			height: wrapper.clientHeight
		};
	}

	public getCurrentModule() {
		return this.projectModules[this.currentModuleIndex];
	}

	public panBy(delta: Point, noIndexUpdate: boolean = false) {
		this.panPosition.x += delta.x;
		this.panPosition.y += delta.y;
		this.setNextClientRect();

		const hasBeenPanned = this.hasBeenPanned();
		if (!menu.backToStartShowing && hasBeenPanned) {
			menu.showBackToStart();
		} else if (menu.backToStartShowing && !hasBeenPanned) {
			menu.hideBackToStart();
		}

		this.videoLoopModules.forEach(module => {
			module.onPositionUpdate(this.panPosition);
		});

		this.introductionModules.forEach(module => {
			module.onPositionUpdate(this.panPosition);
		});

		if (device.isTouch() && !noIndexUpdate) {
			let i = 0;
			for (i = 0; i < this.projectModuleElements.length; i++) {
				if (this.panPosition.x + this.projectModuleElements[i].offsetLeft > WindowManager.halfWidth) {
					break;
				}
			}
			this.setModuleIndex(i - 1);
		}
	}

	public unload() {
		this.introductionModules.forEach(item => {
			item.unload();
		});

		this.imageModules.forEach(item => {
			item.unload();
		});

		this.exhibitionModules.forEach(item => {
			item.unload();
		});

		this.videoModules.forEach(item => {
			item.unload();
		});

		this.videoLoopModules.forEach(item => {
			item.unload();
		});
	}

	public hideOtherProjectModuleElements(visibleElement: HTMLDivElement) {
		this.projectModuleElements.forEach(element => {
			if (visibleElement != element) {
				element.style.visibility = 'hidden';
			}
		});
	}

	public showProjectModuleElements() {
		this.projectModuleElements.forEach(element => {
			element.style.visibility = 'visible';
		});
	}

	public isOnScreen() {
		let isOnScreen = false;
		this.projectModuleInnerWrappers.forEach(projectElement => {
			const rect = projectElement.getBoundingClientRect();
			if (rect.bottom >= 0 && rect.right >= 0 && rect.top <= WindowManager.landingHeight && rect.left <= WindowManager.landingWidth) {
				isOnScreen = true;
			}
		});
		return isOnScreen;
	}

	public getAngleToNextModule(point: Point): number {
		let angle;
		if (this.nextModuleRect) {
			angle = this.angle(this.nextModuleRect.left + this.nextModuleRect.width / 2, this.nextModuleRect.top + this.nextModuleRect.height / 2, point.x, point.y) - 90;
		}
		return angle;
	}

	public setModuleIndex(index) {
		mobileProjectNav.enableNext();
		mobileProjectNav.enablePrev();

		this.currentModuleIndex = index;

		if (index <= 0) {
			mobileProjectNav.disablePrev();
		} else if (index >= this.projectModules.length - 1) {
			mobileProjectNav.disableNext();
		}
	}

	private setNextClientRect() {
		let width = 0;
		const boundsX = Math.abs(this.panPosition.x);

		// scroll is past the last item
		if (boundsX > this.element.clientWidth) {
			this.nextModuleRect = this.projectModuleInnerWrappers[this.projectModuleInnerWrappers.length - 1].getBoundingClientRect();
		} else {
			for (let i = 0; i < this.projectModuleInnerWrappers.length; i++) {
				const el = this.projectModuleInnerWrappers[i];
				if (width > boundsX) {
					// todo: optimze this to not check client rect so often
					this.nextModuleRect = el.getBoundingClientRect();
					break;
				}
				width += el.clientWidth;
			}
		}
	}

	private angle(cx, cy, ex, ey) {
		let dy = ey - cy;
		let dx = ex - cx;
		let theta = Math.atan2(dy, dx); // range (-PI, PI]
		theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
		return theta;
	}
}
