import { BlobRing } from './BlobRing';
import { Point } from '../../utils/Point';

export class BlobPoint {
	private parent: BlobRing;
	private point: Point;
	readonly random: number = Math.random();
	readonly speed: number = 0.2;
	readonly spread: number = 7;

	constructor(point: Point, parent: BlobRing) {
		this.point = point;
		this.parent = parent;
	}

	public getPosition(time: number) {
		const radialOffset =
			Math.sin(time * this.speed + this.random * 10000) *
			this.spread *
			this.parent.parent.parent.introBlobRadius *
			this.parent.parent.hoverRadiusMultiplier *
			this.parent.parent.parent.viewportRadiusMultiplier;
		return {
			x: this.parent.parent.centerPoint.x + this.point.x * (this.parent.radius * this.parent.parent.ringScaleX + radialOffset),
			y: this.parent.parent.centerPoint.y + this.point.y * (this.parent.radius + radialOffset)
		};
	}
}
