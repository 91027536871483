import { Power1, Power4, TweenMax } from 'gsap/TweenMax';
import { Draggable } from 'gsap/Draggable';
import { emitter, EVENTS } from '../../utils/emitter';
import { CursorManager, cursorManager } from '../CursorManager/CursorManager';
import { WindowManager } from '../../utils/WindowManager';
import { Point } from '../../utils/Point';
import { ProjectModule } from './ProjectModule';
import { ThrowPropsPlugin } from '../../../lib/com/greensock/gsap-bonus/ThrowPropsPlugin';
import { device } from '../../utils/device';

export class ImageModule extends ProjectModule {
	private imagesElement: HTMLDivElement;
	private infoElement: HTMLDivElement;
	private images: NodeList;
	public featuredImageElement: HTMLDivElement;
	private otherImages: NodeList;
	private slideshowShowing: boolean;
	private currentIndex: number = 0;
	private lastIndex: number = 0;
	private imageSpacing: number = 0;
	private largeImageScale: number = 1.2;
	private mousePoint: Point = { x: 0, y: 0 };
	private mobileIcon: HTMLDivElement;
	private counterCurrentIndexEl: HTMLElement;
	private counterTotalIndexEl: HTMLElement;
	private featuredImageImg: HTMLImageElement;
	private snapPoints = [];
	private draggableInstance;

	constructor(element: HTMLDivElement) {
		super(element);
		this.imagesElement = this.element.querySelector('.images');
		this.infoElement = this.element.querySelector('.info');
		this.images = this.element.querySelectorAll('.image');
		this.mobileIcon = this.element.querySelector('.mobileIcon');
		this.featuredImageElement = this.element.querySelector('.featuredImage');
		this.featuredImageImg = this.element.querySelector('.featuredImage img');
		this.otherImages = this.element.querySelectorAll('.otherImage');
		this.counterCurrentIndexEl = document.querySelector('.slideshowForeground .counter span.current');
		this.counterTotalIndexEl = document.querySelector('.slideshowForeground  .counter span.total');

		if (this.images.length > 1) {
			this.mobileIcon.classList.add('active');
			this.featuredImageElement.addEventListener('mouseover', this.onFeaturedOverHandler);
			this.featuredImageElement.addEventListener('mouseout', this.onFeaturedOutHandler);
			this.featuredImageElement.addEventListener('click', this.onFeaturedClickHandler);

			this.images.forEach(image => {
				image.addEventListener('mouseover', this.onImageOverHandler);
				image.addEventListener('mouseout', this.onImageOutHandler);
			});
		}

		ThrowPropsPlugin;

		Draggable.create(this.imagesElement, {
			type: 'x',
			edgeResistance: 0.65,
			onThrowUpdate: () => {
				let pointFound = false;
				this.snapPoints.forEach((point, index) => {
					if (point - this.draggableInstance.x < 20 && !pointFound) {
						this.counterCurrentIndexEl.innerHTML = (index + 1).toString();
						pointFound = true;
					}
				});
			},
			throwProps: true
		});
		this.draggableInstance = Draggable.get(this.imagesElement);
		this.draggableInstance.disable();
	}

	public transitionIn() {}

	public layout() {
		this.imageSpacing = WindowManager.width / 5;
		let width = 0;
		this.snapPoints = [];
		this.images.forEach(image => {
			const imageDiv = image as HTMLDivElement;
			const imgEl = (image as HTMLDivElement).querySelector('img');
			const aspectRatio = Number(imgEl.getAttribute('data-aspect-ratio'));
			const imgWidth = imgEl.clientHeight * aspectRatio;
			imageDiv.setAttribute('data-width', String(imgWidth));
			imageDiv.style.left = width + 'px';
			this.snapPoints.push(-width);
			width += imgWidth + this.imageSpacing;
			Draggable.get(this.imagesElement).vars.snap = this.snapPoints;
			Draggable.get(this.imagesElement).update();
		});
	}

	private onFeaturedOverHandler = e => {
		if (this.slideshowShowing || device.isTouch()) {
			return;
		}
		TweenMax.to(this.featuredImageImg, 0.3, { opacity: 0.6 });
		this.featuredImageElement.classList.add('over');

		cursorManager.setType(CursorManager.GALLERY);
		cursorManager.expandRings();
	};

	private onFeaturedOutHandler = e => {
		if (this.slideshowShowing || device.isTouch()) {
			return;
		}
		TweenMax.to(this.featuredImageImg, 0.3, {
			opacity: 1,
			onComplete: () => {
				this.featuredImageElement.classList.remove('over');
			}
		});
		cursorManager.setType(CursorManager.COMPASS);
		cursorManager.contractRings();
	};

	private onFeaturedClickHandler = e => {
		if (this.slideshowShowing) {
			return;
		}
		TweenMax.to(this.featuredImageImg, 0.3, { opacity: 1 });
		if (cursorManager.isDragClick) {
			cursorManager.contractRings();
			TweenMax.to(e.currentTarget, 0.2, { opacity: 0.75 });
			emitter.emit(EVENTS.showSlideshow, { instance: this });
		}
	};

	private onImageOverHandler = e => {
		if (this.slideshowShowing) {
			TweenMax.to(e.currentTarget, 0.2, { opacity: 0.75 });
		}
		if (this.images[this.currentIndex] != e.currentTarget) {
			cursorManager.expandRings();
		}
	};

	private onImageOutHandler = e => {
		if (this.slideshowShowing) {
			TweenMax.to(e.currentTarget, 0.2, { opacity: 1 });
		}
		cursorManager.contractRings();
	};

	public showSlideshow() {
		this.slideshowShowing = true;
		this.mobileIcon.style.visibility = 'hidden';
		this.element.style.zIndex = '10';
		this.counterCurrentIndexEl.innerHTML = '1';
		this.counterTotalIndexEl.innerHTML = this.images.length.toString();

		TweenMax.to(this.infoElement, 1, { opacity: 0, ease: Power4.easeOut });
		this.layout();
		this.loadSlideshow();
		TweenMax.to(this.otherImages, 1, { autoAlpha: 1 });
		cursorManager.off(EVENTS.mouseMove, this.cursorMoveHandler);
		cursorManager.on(EVENTS.mouseMove, this.cursorMoveHandler);

		if (device.isTouch()) {
			Draggable.get(this.imagesElement).enable();
		} else {
			TweenMax.to(this.featuredImageElement, 1, { scaleX: this.largeImageScale, scaleY: this.largeImageScale });
			document.body.removeEventListener('mousedown', this.bodyClickHandler);
			document.body.addEventListener('mousedown', this.bodyClickHandler);
		}

		this.checkCursor();
	}

	public hideSlideshow() {
		this.slideshowShowing = false;
		TweenMax.to(this.infoElement, 0.5, { opacity: 1, ease: Power4.easeOut });
		TweenMax.to(this.otherImages, 0.5, { autoAlpha: 0 });
		this.element.style.zIndex = '0';
		this.mobileIcon.style.visibility = 'visible';
		this.gotoImageByIndex(0);
		TweenMax.to(this.featuredImageElement, 0.5, { scaleX: 1, scaleY: 1, onComplete: this.layout, onCompleteScope: this });
		cursorManager.off(EVENTS.mouseMove, this.cursorMoveHandler);
		document.body.removeEventListener('mousedown', this.bodyClickHandler);
		cursorManager.setType(CursorManager.COMPASS);
		Draggable.get(this.imagesElement).disable();
	}

	private bodyClickHandler = e => {
		const cursorType = cursorManager.getType();
		if (cursorType == CursorManager.PREV) {
			this.gotoPreviousImage();
		} else if (cursorType == CursorManager.NEXT) {
			this.gotoNextImage();
		}
	};

	private cursorMoveHandler = e => {
		this.mousePoint = e;
		this.checkCursor();
	};

	private checkCursor() {
		if (this.mousePoint.y > 120 && this.mousePoint.y < WindowManager.height - 140) {
			if (this.mousePoint.x < WindowManager.landingHalfWidth) {
				if (this.currentIndex == 0) {
					cursorManager.setType(CursorManager.POINTER);
				} else {
					cursorManager.setType(CursorManager.PREV);
				}
			} else {
				if (this.currentIndex == this.images.length - 1) {
					cursorManager.setType(CursorManager.POINTER);
				} else {
					cursorManager.setType(CursorManager.NEXT);
				}
			}
		} else {
			cursorManager.setType(CursorManager.POINTER);
		}
	}

	public getCenter() {
		return {
			x: this.element.offsetLeft + this.imagesElement.offsetLeft - WindowManager.landingHalfWidth + this.featuredImageElement.clientWidth / 2,
			y: this.element.offsetTop + this.imagesElement.offsetTop - WindowManager.landingHalfHeight + this.featuredImageElement.clientHeight / 2
		};
	}

	private gotoPreviousImage() {
		const index = this.currentIndex - 1;
		if (index > -1) {
			this.gotoImageByIndex(index);
		}
	}

	private gotoNextImage() {
		const index = this.currentIndex + 1;
		if (index < this.images.length) {
			this.gotoImageByIndex(index);
		}
	}

	private gotoImageByIndex(index) {
		this.lastIndex = this.currentIndex;
		this.currentIndex = index;
		const element = this.images[index] as HTMLDivElement;
		this.imagesElement.style.pointerEvents = 'none';
		if (element) {
			const offset = (Number(this.featuredImageElement.getAttribute('data-width')) - Number(element.getAttribute('data-width'))) / 2;
			const position = element.offsetLeft - offset;
			TweenMax.to(this.images[this.lastIndex], 1, { scaleX: 1, scaleY: 1, ease: Power4.easeOut });
			TweenMax.to(this.images[this.currentIndex], 1, { scaleX: this.largeImageScale, scaleY: this.largeImageScale, ease: Power4.easeOut });
			TweenMax.to(this.imagesElement, 1, {
				x: -position,
				ease: Power4.easeOut,
				onComplete: () => {
					this.imagesElement.style.pointerEvents = 'auto';
				}
			});
			this.counterCurrentIndexEl.innerHTML = index + 1;
		}

		this.checkCursor();
	}

	public load() {
		const img = this.featuredImageElement.querySelector('img');
		emitter.emit(EVENTS.assetLoadStart, { asset: img });
		img.onload = () => {
			emitter.emit(EVENTS.assetLoadComplete, { asset: img });
		};
		img.setAttribute('src', img.getAttribute('data-src'));
	}

	public loadSlideshow() {
		this.otherImages.forEach(image => {
			const img = (image as HTMLDivElement).querySelector('img');
			img.setAttribute('src', img.getAttribute('data-src'));
		});
	}

	public unload() {}
}
