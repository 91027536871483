export function clamp(n, min, max) {
	return Math.max(min, Math.min(n, max));
}

export function mod(n, m) {
	return ((n % m) + m) % m;
}

export function pointCircleIntersection(point, circle) {
	return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
}

export function rectangleIntersection(rect1, rect2) {
	return rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x && rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y;
}

export function distance(x1, y1, x2, y2) {
	return Math.hypot(x2 - x1, y2 - y1);
}

export function scale(num, in_min, in_max, out_min, out_max) {
	return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function pad(num, size) {
	let s = num + '';
	while (s.length < size) s = '0' + s;
	return s;
}

export function hexToRgb(hex) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
		  }
		: null;
}

export function lerp(v0, v1, t) {
	return v0 * (1 - t) + v1 * t;
}

export function lerpRGB(color1, color2, t) {
	return 'rgb(' + lerp(color1.r, color2.r, t) + ',' + lerp(color1.g, color2.g, t) + ',' + lerp(color1.b, color2.b, t) + ')';
}

export function sec2time(timeInSeconds) {
	var pad = function(num, size) {
		return ('000' + num).slice(size * -1);
	};
	var time = parseFloat(timeInSeconds).toFixed(3);
	// @ts-ignore
	var minutes = Math.floor(time / 60) % 60;
	// @ts-ignore
	var seconds = Math.floor(time - minutes * 60);
	var milliseconds = time.slice(-3);
	return pad(minutes, 2) + ':' + pad(seconds, 2);
}
