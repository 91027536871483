import { Point } from '../../utils/Point';
import { TweenMax } from 'gsap/TweenMax';
import { Emitter, EVENTS } from '../../utils/emitter';
import { device } from '../../utils/device';

export class CursorManager extends Emitter {
	public static POINTER: string = 'pointer';
	public static DEFAULT: string = 'default';
	public static PLAY: string = 'play';
	public static PAUSE: string = 'pause';
	public static COMPASS: string = 'compass';
	public static GALLERY: string = 'gallery';
	public static OPEN: string = 'open';
	public static OPEN_OUTSIDE: string = 'openOutside';
	public static CLOSE: string = 'close';
	public static NEXT: string = 'next';
	public static PREV: string = 'previous';

	private static CURSORS_IDENTIFIER: string = '.CursorManager';
	private static EVENT_TARGET_IDENTIFIER: string = '.Landing';

	public downMousePoint: Point = { x: 0, y: 0 };
	public lastTargetMousePoint: Point = { x: 0, y: 0 };
	public dragging: boolean;
	public isDragClick: boolean;
	public lastCursorType: string;
	public initialized: boolean;
	public pointerCursorDot: HTMLDivElement;

	private cursorsElement: HTMLDivElement;
	private cursorEventTarget: HTMLDivElement;
	private activeCursor: HTMLDivElement;
	private defaultCursorDot: HTMLDivElement;
	private compassCursorDot: HTMLDivElement;
	private compassCursor: HTMLDivElement;
	private rings: NodeList;

	private type: string;

	public constructor() {
		super();
		this.cursorsElement = document.body.querySelector(CursorManager.CURSORS_IDENTIFIER);
		this.cursorEventTarget = document.body.querySelector(CursorManager.EVENT_TARGET_IDENTIFIER);
		this.rings = this.cursorsElement.querySelectorAll('.ring');
		this.defaultCursorDot = this.cursorsElement.querySelector('.cursor.' + CursorManager.DEFAULT + ' .dot');
		this.pointerCursorDot = this.cursorsElement.querySelector('.cursor.' + CursorManager.POINTER + ' .dot');
		this.compassCursorDot = this.cursorsElement.querySelector('.cursor.' + CursorManager.COMPASS + ' .dot');
		this.compassCursor = this.cursorsElement.querySelector('.cursor.compass');
		this.cursorsElement.querySelectorAll('.cursor').forEach(cursor => {
			(cursor as HTMLDivElement).style.display = 'none';
		});
		this.cursorsElement.style.display = 'block';
		this.move({ x: -100, y: -100 });
	}

	public init() {
		this.initialized = true;
		this.contractRings(0);
		this.addEvents();
		document.documentElement.classList.add('noCursor');
	}

	private addEvents() {
		this.cursorEventTarget.onmousedown = event => {
			this.grab({ x: event.clientX, y: event.clientY });
		};

		window.onmouseup = event => {
			this.ungrab({ x: event.clientX, y: event.clientY });
		};

		document.ontouchstart = event => {
			return this.isSingleTouch(event);
		};

		document.ontouchmove = event => {
			return this.isSingleTouch(event);
		};

		document.ontouchend = event => {
			return this.isSingleTouch(event);
		};

		this.cursorEventTarget.ontouchstart = event => {
			if (this.isSingleTouch(event)) {
				this.grab({ x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY });
			}
		};

		this.cursorEventTarget.ontouchmove = event => {
			if (this.isSingleTouch(event)) {
				this.move({ x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY });
			}
		};

		this.cursorEventTarget.ontouchend = event => {
			if (this.isSingleTouch(event)) {
				this.ungrab({ x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY });
			}
		};

		window.onmousemove = event => {
			if (this.initialized) {
				document.body.style.cursor = 'none';
			}
			this.move({ x: event.clientX, y: event.clientY });
		};

		document.onmouseleave = event => {
			this.cursorsElement.style.display = 'none';
		};

		document.onmouseenter = event => {
			this.cursorsElement.style.display = 'block';
		};
	}

	private isSingleTouch(event) {
		if (event.touches.length > 1) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
		return true;
	}

	public setType(type: string) {
		if (!this.initialized || type == this.type || !type || device.isTouch()) {
			return;
		}

		this.lastCursorType = this.type;
		this.type = type;

		if (this.activeCursor) {
			this.activeCursor.style.display = 'none';
		}

		if (this.lastCursorType != type) {
			this.activeCursor = this.getCursor(type);
			this.activeCursor.style.display = 'block';
		}
	}

	public getType(): string {
		return this.type;
	}

	public getCursor(type): HTMLDivElement {
		return this.cursorsElement.querySelector('.cursor.' + type);
	}

	public setCompassAngle(angle) {
		TweenMax.set(this.compassCursor, { rotation: angle });
	}

	public expandRings(speed = 0.5) {
		TweenMax.to(this.rings, speed, { attr: { r: 21.5 } });
	}

	public contractRings(speed = 0.5) {
		TweenMax.to(this.rings, speed, { attr: { r: 16 } });
	}

	private grab(point: Point) {
		this.isDragClick = false;
		TweenMax.to([this.defaultCursorDot, this.compassCursorDot], 0.25, { scaleX: 0.5, scaleY: 0.5, transformOrigin: '50% 50%' });
		this.lastTargetMousePoint.x = this.downMousePoint.x = point.x;
		this.lastTargetMousePoint.y = this.downMousePoint.y = point.y;
		this.dragging = true;
		this.emit(EVENTS.mouseDown, point);
	}

	private ungrab(point) {
		if (!this.dragging) {
			return;
		}
		this.dragging = false;
		TweenMax.to([this.defaultCursorDot, this.compassCursorDot], 0.25, { scaleX: 1, scaleY: 1 });
		if (Math.abs(this.downMousePoint.x - point.x) < 2 && Math.abs(this.downMousePoint.y - point.y) < 2) {
			this.isDragClick = true;
			this.emit(EVENTS.dragClick, point);
		}
		this.emit(EVENTS.mouseUp, point);
	}

	private move(point: Point) {
		let w = 0;
		let h = 0;
		if (this.activeCursor) {
			w = this.activeCursor.clientWidth / 2;
			h = this.activeCursor.clientHeight / 2;
		}

		this.cursorsElement.style.transform = 'translate3d(' + (point.x - w) + 'px, ' + (point.y - h) + 'px, 0)';

		this.emit(EVENTS.mouseMove, point);
		this.lastTargetMousePoint.x = point.x;
		this.lastTargetMousePoint.y = point.y;
	}
}

export const cursorManager = new CursorManager();
